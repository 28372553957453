"use client";

import style from "../components.module.css";
import loader from "../../assets/images/loader.gif";
import { useEffect } from "react";

export default function Loading() {


    return (<div className={style.loader}>
        <img
            src={loader}
            alt="Loading Image"
        />
    </div>);
}