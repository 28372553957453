import React from 'react';
import styles from "./policies.module.css";


const PrivacyPolicy = () => {
  return (
    <div className={styles.policy}>
      <h1>Privacy Policy</h1>
      <p>
        We value the trust you place in us. That's why we insist upon the highest standards for secure
        transactions and customer information privacy. Please read the following statement to learn about our
        information gathering and dissemination practices.
      </p>

      <div className={styles.note}>
        <strong>Note:</strong> Our privacy policy is subject to change at any time without notice. To make sure
        you are aware of any changes, please review this policy periodically.
      </div>

      <p>
        By visiting this Website, you agree to be bound by the terms and conditions of this Privacy Policy. If
        you do not agree, please do not use or access our Website.
      </p>

      <p>
        By mere use of the Website, you specifically consent to our use and disclosure of your personal
        information in accordance with this Privacy Policy. This Privacy Policy is incorporated into and subject
        to the Terms of Use.
      </p>

      <h2>1) Collection of Personally Identifiable Information and Other Information</h2>
      <p>
        When you use our Website, we collect and store your personal information which is provided by you from
        time to time. Our primary goal in doing so is to provide you a safe, efficient, smooth and customized
        experience. This allows us to provide services and features that most likely meet your needs, and to
        customize our Website to make your experience safer and easier. More importantly, while doing so we
        collect personal information from you that we consider necessary for achieving this purpose.
      </p>

      <p>
        In general, you can browse the Website without telling us who you are or revealing any personal
        information about yourself. Once you give us your personal information, you are not anonymous to us.
        Where possible, we indicate which fields are required and which fields are optional. You always have the
        option to not provide information by choosing not to use a particular service or feature on the Website.
      </p>

      <p>
        We may automatically track certain information about you based upon your behavior on our Website. We use
        this information to do internal research on our users' demographics, interests, and behavior to better
        understand, protect and serve our users. This information is compiled and analyzed on an aggregated
        basis. This information may include the URL that you just came from (whether this URL is on our Website
        or not), which URL you next go to (whether this URL is on our Website or not), your computer browser
        information, and your IP address.
      </p>

      <h3>Cookies</h3>
      <p>
        We use data collection devices such as "cookies" on certain pages of the Website to help analyze our web
        page flow, measure promotional effectiveness, and promote trust and safety. "Cookies" are small files
        placed on your hard drive that assist us in providing our services.
      </p>

      <p>
        We also use cookies to allow you to enter your password less frequently during a session. Cookies can
        also help us provide information that is targeted to your interests. Most cookies are "session cookies,"
        meaning that they are automatically deleted from your hard drive at the end of a session. You are always
        free to decline our cookies if your browser permits, although in that case you may not be able to use
        certain features on the Website and you may be required to re-enter your password more frequently during
        a session.
      </p>

      <p>
        Additionally, you may encounter "cookies" or other similar devices on certain pages of the Website that
        are placed by third parties. We do not control the use of cookies by third parties.
      </p>

      <h2>2) Use of Demographic / Profile Data / Your Information</h2>
      <p>
        We use personal information to provide the services you request. To the extent we use your personal
        information to market to you, we will provide you the ability to opt-out of such uses. We use your
        personal information to resolve disputes; troubleshoot problems; help promote a safe service; collect
        money; measure consumer interest in our products and services, inform you about online and offline
        offers, products, services, and updates; customize your experience; detect and protect us against error,
        fraud and other criminal activity; enforce our terms and conditions; and as otherwise described to you at
        the time of collection.
      </p>

      <h2>3) Sharing of Personal Information</h2>
      <p>
        We may share personal information with our other corporate entities and affiliates to help detect and
        prevent identity theft, fraud and other potentially illegal acts; correlate related or multiple accounts
        to prevent abuse of our services; and to facilitate joint or co-branded services that you request where
        such services are provided by more than one corporate entity.
      </p>

      <p>
        We may disclose personal information if required to do so by law or in the good faith belief that such
        disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process.
      </p>

      <h2>4) Security Precautions</h2>
      <p>
        Our Website has stringent security measures in place to protect the loss, misuse, and alteration of the
        information under our control. Whenever you change or access your account information, we offer the use
        of a secure server. Once your information is in our possession, we adhere to strict security guidelines,
        protecting it against unauthorized access.
      </p>

      <h2>5) Choice/Opt-Out</h2>
      <p>
        We provide all users with the opportunity to opt-out of receiving non-essential (promotional,
        marketing-related) communications from us on behalf of our partners, and from us in general, after
        setting up an account.
      </p>

      <h2>6) Your Consent</h2>
      <p>
        By using the Website and/ or by providing your information, you consent to the collection and use of the
        information you disclose on the Website in accordance with this Privacy Policy.
      </p>

      <div className={styles.cookiePolicy}>
        <p>
          <strong>Cookies:</strong> A "cookie" is a small piece of information stored by a web server on a web
          browser so it can be later read back from that browser. Cookies are useful for enabling the browser to
          remember information specific to a given user.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
