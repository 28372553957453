"use client"
import config from "../../../config/config";
import teaImages from "../../../utils/import_images_product_list";
import styles from "../product.module.css";
import { useState } from "react";


export default function ProductImage(params) {

    const [showImage, setShowImage] = useState(0);


    return (
        <div className={styles.product_img}>

            <div className={styles.main_image}>
                <img src={`${config.URL}${params.product.thumbnailImg}`}
                    alt="Image List"
                    className={styles.pro_main_img}

                />
            </div>
            <div className={styles.image_list}>
                {
                    teaImages[params.product.slug]?.map((item, index) => {
                        return (
                            <img src={item}
                                style={index != 0 ? { opacity: index == showImage ? 6 : 0.7 } : { opacity: index == showImage ? 6 : 0.7 }}
                                onMouseEnter={() => {
                                    setShowImage(index)
                                }}
                                alt="Image List"
                                className={styles.pro_img}
                            />
                        )
                    })
                }

            </div>
        </div>
    )
}

