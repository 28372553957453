const apis = [
    {
      name: 'login',
      path: `/users/loginOrSingup`,
      method: 'POST'
    },
    {
      name:'verify-otp',
      path:'/users/verifyOtp',
      method:'PUT'
    },
    {
      name:'check-token',
      path:'/users',
      method:'GET'
    },
    {
      name:'fetch-product-id',
      path:'/products/fetch-product/',
      method:'GET'
    },
    {
      name:'fetch-product-slug',
      path:'/products/fetch-product-slug/',
      method:'GET'
    },
    {
      name:'fetch-products',
      path:'/products/fetch-product/',
      method:'GET'
    },
    {
      name:'get-cart-items',
      path:'/carts/get-cart',
      method:'GET'
    },
    {
      name:'add-to-cart',
      path:'/carts/add-cart',
      method:'POST',
    },
    {
      name:'increase-quantity',
      path:'/carts/increment/',
      method:'PATCH'
    },
    {
      name:'decrease-quantity',
      path:'/carts/decrement/',
      method:'PATCH'
    },
    {
      name:'clear-cart',
      path:'/carts/remove',
      method:'DELETE'
    },
    {
      name:'bulk-products',
      path:'/carts/bulk-cart',
      method:"POST"
    },
    {
      name:"create-order",
      path:"/orders/create-order",
      method:"POST"
    },
    {
      name:"complete-order",
      path:"/orders/complete-order",
      method:"POST"
    },
    {
      name:"get-orders",
      path:"/orders/",
      method:"GET"
    },
    {
      name:"get-address",
      path:"/address/",
      method:"GET"
    },
    {
      name:"add-address",
      path:"/address/",
      method:"POST"
    },
    {
      name:"update-address",
      path:"/address/",
      method:"PUT"
    },
    {
      name:"rate-order",
      path:"/orders/rate",
      method:"POST"
    },
    {
      name:"apply-coupons",
      path:"/coupons/apply/",
      method:"GET"
    },
    {
      name:"edit-profile",
      path:"/users/",
      method:"GET"
    }
  ];
  export default apis;
  