import blackTeaFront250g from "../assets/images/black-tea/Black-tea-frontjpg1.webp";
import blackTeaBack250g from "../assets/images/black-tea/Black-tea-back1.webp"
import blackTeaBenifits250gm from "../assets/images/black-tea/Black--tea-bebefits.webp"
import blackteaFront500gm from "../assets/images/black-tea/Black-tea-pouch1.webp"
import blackteaBack500gm from "../assets/images/black-tea/Black-tea-pouch-back-sidejpg1.webp"
import blackTeaBenifits500gm from "../assets/images/black-tea/Black--tea-bebefits-pouich.webp";
import immuneFront100gm from "../assets/images/boosty-immune/front1.webp"
import immuneBack100gm from "../assets/images/boosty-immune/back.webp"
import immuneBenifits from "../assets/images/boosty-immune/benefits.webp";
import elaichiFrontTea from "../assets/images/elaichi-Tea/Front.webp";
import elaichiBackTea from "../assets/images/elaichi-Tea/Back.webp"
import elaichiBenefits from "../assets/images/elaichi-Tea/Benefits.webp";
import tulsiFrontTea from "../assets/images/tusli-green-tea-100gm/front.webp";
import tulsiBackTea from "../assets/images/tusli-green-tea-100gm/back-side.webp";
import tulsiBenifits from "../assets/images/tusli-green-tea-100gm/benefits.webp";
import greenTeaFront250gm from "../assets/images/Green-Tea/front-view.webp";
import greenTeaBack250gm from "../assets/images/Green-Tea/Backside.webp";
import greenTeaFront250gmBefinits from "../assets/images/Green-Tea/Benefits-of-green-Tea.webp";
import greenTeaFront500gm from "../assets/images/Green-Tea/Green-tea-pouch-front.webp";
import greenTeaBack500gm from "../assets/images/Green-Tea/Green-tea-pouch-back-side.webp";
import slippy from "../assets/images/Sippy-clam-tea/front.webp";
import slippyBack from "../assets/images/Sippy-clam-tea/back.webp"
import slippyBenifits from "../assets/images/Sippy-clam-tea/benfits-of-sippy-clam-tea.webp"


const teaImages = {
    "kanwari-darjeeling-black-tea-250gm":[blackTeaFront250g,blackTeaBack250g,blackTeaBenifits250gm],
    "kanwari-darjeeling-black-tea-500gm":[blackteaFront500gm,blackteaBack500gm,blackTeaBenifits500gm],
    "kanwari-boosty-immune-green-tea-100gm":[immuneFront100gm,immuneBack100gm,immuneBenifits],
    "kanwari-eliche-cardamom-black-tea-4-pouch":[elaichiFrontTea,elaichiBackTea,elaichiBenefits],
    "tulsi-green-tea-100gm":[tulsiFrontTea, tulsiBackTea,tulsiBenifits],
    "kanwari-green-tea-250gm":[greenTeaFront250gm, greenTeaBack250gm,greenTeaFront250gmBefinits],
    "kanwari-green-tea-500gm":[greenTeaFront500gm,greenTeaBack500gm],
    "kanwari-sippy-calm-green-tea-100gm":[slippy,slippyBack,slippyBenifits]
}

export default teaImages;