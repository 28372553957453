"use client";

import styles from "../product.module.css";
import NameBanners from "./name_banner";
import ProductImage from "./product_images";
import ProductContent from "./product_content";
import Description from "./description";
import ProductList from "../../home/components/product_list";
import Testimonial from "../../home/components/testinomial";
import SEO from "../../../components/seo/seo";
import BrandBanner from "../../home/components/brand_banner";
import BestSellingProductList from "../../home/components/BestSellingProduct";

export default function ProductsPage(params) {
    return (
        <>
            <SEO
                title={params.product.title}
                description={params.product.breif_description}
            />
            <div className={styles.product_details}>
                <NameBanners product={params.product} />
                <div className={styles.product_detail_container}>
                    <div className={styles.product_images}>
                        <ProductImage product={params.product} />
                    </div>
                    <div className={styles.product_detail_content}>
                        <ProductContent product={params.product} isProductInCart={params.isProductInCart}/>
                    </div>
                </div>
    
                <div style={{ width: "100%" }}>
                    <BestSellingProductList  />
                </div>
                <div style={{ width: "100%" }}>
                    <Testimonial />
                </div>
                <div style={{ width: "100%" }}>
                    <BrandBanner />
                </div>
            </div>
        </>
    );
}


