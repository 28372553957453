import React from 'react';
import style from "./policies.module.css";

const CancellationReturnPolicy = () => {
  return (
    <div className={style.policy}>
      <h1>Cancellation/Return Policy</h1>
      <p>Thank you for choosing <strong>Kanwari</strong>.</p>
      <p>If you aren’t satisfied with your order, you can reach out to <a href="mailto:info@kanwari.com">info@kanwari.com</a> and our Customer Experience agents will be happy to assist you promptly.</p>

      <h2>Cancellations</h2>
      <p>All cancellation requests must be initiated within 12 hours of placing the order or before your order has been shipped, whichever happens first.</p>
      <p>In case your order has already been shipped, it cannot be cancelled as our service partners charge us as soon as the pick-up is completed.</p>
      <p>After cancellation, a refund will be processed within 5-7 working days.</p>

      <h2>Replacements</h2>
      <p>Please do not accept the parcel if the sealing tape is tampered with.</p>
      <p>A request for replacement must be initiated within a maximum of 5 days from the day of delivery and will only be accepted in the cases mentioned below:</p>
      <ul>
        <li><strong>Damaged/Incorrect Product Received:</strong> Please attach a picture of the delivered order along with the invoice for our internal quality checks. Allow us 24 hours to review your ticket and send you a replacement after necessary internal checks, if applicable.</li>
        <li><strong>Item missing from order:</strong> Please attach an image of the invoice of the delivered parcel. Allow us 24 hours to review your ticket and send you a replacement after necessary internal checks, if applicable.</li>
        <li><strong>Expired Product Received:</strong> Please attach a picture of the product showing the expiry date along with the invoice. Allow us 24 hours to review your ticket and send you a replacement after necessary internal checks, if applicable.</li>
      </ul>

      <h2>Returns</h2>
      <p>We do not accept returns on any products because:</p>
      <ul>
        <li>We wish to keep our carbon footprint in check.</li>
        <li>F&B products lose freshness over the course of return process.</li>
      </ul>

      <h2>Conditions under which replacement/cancellation requests will not be accepted:</h2>
      <ul>
        <li>The replacement request is generated after 5 days from the date of delivery.</li>
        <li>The damaged/missing product is reported after 5 days from the date of delivery.</li>
        <li>The cancellation request is generated after 4 hours of placing the order or before your order has been shipped, whichever happens first.</li>
        <li>Wrong product was ordered by the customer.</li>
      </ul>
    </div>
  );
};

export default CancellationReturnPolicy;
