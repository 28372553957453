
import styles from "./history.module.css";
import historyImage from "../../assets/image/YourHistoryImage.png"
import StarIcon from '@mui/icons-material/Star';
import { useEffect, useState } from "react";
import { apiFunc } from "../../service/service";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import config from "../../config/config";
import LoadingHistory from "./components/LoadingHistory";
export default function History() {


    const [orderHistory, setOrderHistory] = useState({});
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const fetchMyOrders = async () => {
        try {
            const response = await apiFunc("get-orders", "service", [], {});
            if (response.statusCode == 200) {
                setOrderHistory(response.data);
                console.log(response.data, "response.data");
                setLoading(false);
            }
        }
        catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchMyOrders();
    }, []);


    const rateMyOrder = async (_id, rate) => {
        try {
            const response = await apiFunc("rate-order", "service", [], { orderId: _id, rating: rate });
            if (response.statusCode == 200) {
                fetchMyOrders();
            }
        }
        catch (error) {
            console.log()
        }
    }


    return (
        <div className={styles.history_container}>
            <div className={styles.history_banner}>
                <img src={historyImage} />
            </div>
            <div className={styles.history_details}>
                {/* <div className={styles.history_filter}>
                    <TuneIcon style={{ color: "#3D3C3C", fontSize: "30px" }} />
                    <div className={styles.search_box}>
                        <div className={styles.search_icon}>
                            <SearchIcon style={{ color: "#3D3C3C" }} />
                        </div>
                        <div className={styles.search_input}>

                            <input

                            />
                        </div>
                    </div>
                </div> */}
                {

                    loading ? <>
                        <LoadingHistory />
                        <LoadingHistory />
                        <LoadingHistory />
                        <LoadingHistory />
                    </> : <>{
                        orderHistory?.docs?.map((item, index) => {
                            return (
                                <>
                                    <div
                                        onClick={() => {
                                            navigate(`/order-detail/${item.orderId}`);
                                        }}
                                    >
                                        <div className={styles.history_product_details}>
                                            <div className={styles.product_detail}>
                                                <img src={`${config.URL}${item.orders[0].item.thumbnailImg}`} />
                                                <div className={styles.product_name_quantity}>
                                                    <p className={styles.product_quantity}>+ {item.orders.length} More Items Deliverd</p>
                                                    <p className={styles.product_name}>{item.orders[0].item.productName}</p>
                                                </div>
                                            </div>
                                            <div className={styles.rating_review}>
                                                <p className={styles.rating_review_heading}>Rate Your Order</p>
                                                <div className={styles.starts}>
                                                    {[1, 2, 3, 4, 5].map((star) => (
                                                        <StarIcon
                                                            key={star}
                                                            style={{
                                                                color: item?.rating >= star ? "#FFD700" : "#D9D9D9", // Gold if rating is equal or higher
                                                                fontSize: "20px",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={async (e) => {
                                                                e.preventDefault();  // Prevent any default action
                                                                e.stopPropagation(); // Stop event from bubbling 
                                                                await rateMyOrder(item?._id, star);
                                                                e.stopPropagation();
                                                            }}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                            <div className={styles.delivery_status}>
                                                <p className={styles.status_date}>{item.orderStatus} on {
                                                    item?.orderStatusProgress?.map((itm, index) => {
                                                        if (itm.status == item.orderStatus) {
                                                            return (
                                                                <>{moment(itm.date).format("DD/MM/YYYY")}</>
                                                            )
                                                        }
                                                    })}</p>
                                                {/* <p className={styles.status}>you item has been delivered</p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <hr className={styles.horizontol_name} />
                                </>
                            );
                        })

                    }</>


                }

            </div>
        </div>
    );
}
