
import { useState } from "react";
import styles from "./checkout.module.css";
import Address from "./components/Address";
import CheckoutLogin from "./components/CheckoutLogin";
import { useDispatch, useSelector } from "react-redux";
import { apiFunc } from "../../service/service";
import { clearCart } from "../../redux/actions/cartActions";
import { useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import config from "../../config/config";


export default function CheckoutPage() {

    const cartReducer = useSelector((state) => state.cartReducer.value);
    const authValue = useSelector((state) => state.authReducer.value);
    const isLoggedLoading = useSelector((state) => state.authReducer.loading);
    // 
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [billingAddress, setBillingAddress] = useState("");
    const [coupon, setCoupon] = useState("");
    const [couponDiscount, setCouponDiscount] = useState(0);
    const [textCoupon, setTextCoupon] = useState("");
    const [loading, setLoading] = useState(false);
    const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn)
    const [discountPercentage, setDiscountPercentage] = useState(0);


    const totalPrice = cartReducer.length && cartReducer?.reduce((acc, item) => acc + (item?.product?.price * item?.quantity), 0);
    const totalDiscount = cartReducer.length && cartReducer?.reduce((acc, item) => acc + ((item?.product?.comparePrice - item?.product?.price) * item.quantity), 0);
    const totalAmount = cartReducer.length && cartReducer?.reduce((acc, item) => acc + ((item?.product?.comparePrice) * item?.quantity), 0);

    const deliveryCharges = 0; // Assuming free delivery

    const createOrder = async () => {
        try {
            if (billingAddress.length == 0) {
                toast.error("Please select delivery address");
                return;
            }
            setLoading(true);
            const data = {
                address: billingAddress,
                couponCode: coupon
            }
            const response = await apiFunc("create-order", "service", [], data);
            if (response.statusCode == 200) {
                setLoading(false);
                completeOrder(response.data);
            }
        }
        catch (error) {
            console.log(error, "My Error");
            setLoading(false);
        }
    }


    const completeOrder = async (data) => {
        try {
            const { totalAmount, orderId, id } = data;
            console.log(data, "Testing Data");
            const options = {
                key: config.RAZORPAY_KEY,
                name: "Kanwari",
                amount: totalAmount.toString(),
                order_id: id,
                currency: "INR",
                callback_url: 'https://your-server/callback_url',
                handler: async function (response) {
                    const data_ = {
                        orderId: orderId,
                        rpay_orderId: response.rpay_orderId,
                    };
                    try {
                        //set state
                        setLoading(true)
                        const res = await apiFunc(`complete-order`, `service`, [], data_);
                        clearCart(dispatch)
                        navigate("/history");
                        toast.success("Your Order Placed Successfully");
                    }
                    catch (e) {
                        //set state
                        console.log(e, "My WP Error");
                        setLoading(false)
                    }
                },
                prefill: {
                    name: authValue?.name,
                    email: authValue?.email,
                    contact: authValue?.mobileNumber,
                },
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        }
        catch (err) {
            console.log("My Error", err);
        }
    }

    const applyCoupons = async () => {
        try {
            setLoading(true);
            const response = await apiFunc("apply-coupons", "service", [textCoupon], {});
            if (response.statusCode == 200) {
                setCoupon(response.data.code);
                let discountAmount = Math.floor(totalPrice * (response.data.discount / 100))
                setDiscountPercentage(response.data.discount)
                setCouponDiscount(discountAmount);
                setLoading(false);
            }
        }
        catch (error) {
            toast.error(error.message);
            setLoading(false);
        }
    }


    return (
        <>

            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <div className={styles.checkout}>

                <div className={styles.checkout_details}>
                    <div className={styles.checkout_detail_body}>
                        <CheckoutLogin setLoading={setLoading}/>
                        {
                            isLoggedIn ?
                                <Address setBillingAddress={setBillingAddress} />
                                : null
                        }
                    </div>
                </div>
                <div className={styles.checkout_price_detail}>
                    <div className={styles.price_detail}>
                        <div className={styles.price_detail_heading}>
                            <p>Price Detail</p>
                        </div>
                        <hr />
                        <div className={styles.total_price_detail}>
                            <div className={styles.p_detail}>
                                <p>Total Items</p>
                                <p>{cartReducer?.length}</p>
                            </div>
                            <div className={styles.p_detail}>
                                <p>Price</p>
                                <p>₹{totalAmount}</p>
                            </div>
                            <div className={styles.p_detail}>
                                <p style={{ color: "green" }}>Total Saved</p>
                                <p style={{ color: "green", textDecorationLine: "line-through" }}>-₹{totalDiscount}</p>
                            </div>
                            {
                                coupon.length > 0 ? <>
                                    <div className={styles.p_detail}>
                                        <p style={{ color: "green" }}>Coupon Discount</p>
                                        <p style={{ color: "green", textDecorationLine: "line-through" }}>- {discountPercentage}%</p>
                                    </div>
                                </> : <></>
                            }


                            <div className={styles.p_detail}>
                                <p >Delivery Charge</p>
                                <p >₹ {totalPrice > 299 ? 'Free' : 50}</p>
                            </div>
                            <div className={styles.p_detail} >
                                <p style={{ fontSize: "18px" }}>Final Price</p>
                                <p style={{ fontSize: "18px" }}>₹{totalPrice > 299 ? (totalPrice - couponDiscount) : ((totalPrice - couponDiscount) + 50)}</p>
                            </div>
                        </div>
                        <hr />
                        <div className={styles.coupon_container}>
                            <div className={styles.coupon_apply}>
                                <input
                                    value={textCoupon}
                                    onChange={(e) => {
                                        setTextCoupon(e.target.value);
                                    }}
                                />
                                <p
                                    onClick={() => {
                                        if (textCoupon.length < 5) {
                                            toast.error("Please enter valid coupon");
                                        }
                                        else {
                                            applyCoupons();
                                        }
                                    }}
                                >Apply</p>
                            </div>
                        </div>
                        <hr />
                        {
                            isLoggedIn ?
                                <div className={styles.place_order_button} >
                                    <button onClick={() => {
                                        createOrder();
                                    }}>Place Order</button>
                                </div> : null
                        }
                        {/* <div className={styles.ribbon}>
                            <p>Sorry for the inconvenience, we will be live soon!</p>
                        </div> */}
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}
