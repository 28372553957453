"use client"

import { useRef } from "react";
import styles from "../home.module.css";
import kanwariOne from "../../../assets/images/kanwari01.webp"
import kanwariTwo from "../../../assets/images/kanwari02.webp"
import kanwariThree from "../../../assets/images/kanwari03.webp"
import kanwariFour from "../../../assets/images/kanwari04.webp"
import kanwariSeven from "../../../assets/images/kanwari07.webp"
import kanwariEight from "../../../assets/images/kanwari08.webp"
import kanwariNine from "../../../assets/images/kanwari09.webp"
import kanwariTen from "../../../assets/images/kanwari10.webp"
import Slider from "react-slick";
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';


export default function BrandBanner() {

    const sliderReference = useRef();



    let images_ban = [kanwariOne, kanwariTwo, kanwariThree, kanwariFour, kanwariSeven, kanwariEight, kanwariNine, kanwariTen];

    var settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        autoplay: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
    };

    return (
        <div className={styles.brand_banners}>
            <div className={styles.product_heading}>
                <h1 ></h1>
                <div className={styles.arrow_icons}>
                    <div onClick={() => {
                        sliderReference.current.slickPrev();

                    }} className={styles.previous_button}>
                        <WestIcon

                            className={styles.previous_button_icons} />
                    </div>
                    <div onClick={() => {
                        sliderReference.current.slickNext();

                    }} className={styles.next_button}>
                        <EastIcon

                            className={styles.next_button_icons} />
                    </div>
                </div>
            </div>
            <Slider {...settings} ref={sliderReference} >

                {
                    images_ban.map((item, index) => {
                        return (
                            <div className={styles.kanwari_image_container} key={index}>
                                <img src={item}

                                    key={index}
                                    className={styles.kanwari_images}
                                    alt="Kanwari imgs"
                                />
                            </div>
                        );
                    })

                }
            </Slider>
        </div>
    )
}