import styles from "../home.module.css";
import Slider from "react-slick";
import { useRef, useState } from "react";
import productData from "../../../database/products.json"
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import Cards from "../../../components/Cards/Cards";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import CardLoader from "../../../components/Cards/CardLoader";

export default function PopularProductList({ dontShow }) {
    const sliderReference = useRef();

    const cartReducer = useSelector((state) => state.cartReducer.value);
    const products = useSelector((state) => state.productReducer.value);
    const loading = useSelector((state) => state.productReducer.loading);

    useEffect(() => {
        console.log(products, loading, "Product Details");

    }, [products,]);

    var settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 5,
        autoplay: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1380,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    dots: false
                }
            },
            {
                breakpoint: 1270,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    dots: false
                }
            },
            {
                breakpoint: 1147,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    dots: false
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
    };

    return (
        <div className={styles.product_list}>
            <div className={styles.product_heading}>
                <h1 >Our Products</h1>
                <div className={styles.arrow_icons}>
                    <div onClick={() => {
                        sliderReference.current.slickPrev();

                    }} className={styles.previous_button}>
                        <WestIcon

                            className={styles.previous_button_icons} />
                    </div>
                    <div onClick={() => {
                        sliderReference.current.slickNext();

                    }} className={styles.next_button}>
                        <EastIcon

                            className={styles.next_button_icons} />
                    </div>
                </div>
            </div>
            {
                <div className={styles.product_cards}>
                    {
                        loading ? <Slider {...settings} ref={sliderReference}>
                            <CardLoader></CardLoader><CardLoader></CardLoader><CardLoader></CardLoader><CardLoader></CardLoader>

                        </Slider> : <Slider {...settings} ref={sliderReference}>


                            {
                                products?.docs?.map((item, index) => {
                                    if (item.slug !== dontShow) {

                                        return (
                                            <>
                                                <Cards data={item} key={index} index={index} cartItems={cartReducer} />
                                            </>
                                        )
                                    }
                                })
                            }
                        </Slider>
                    }
                </div>
            }

        </div>
    );
}