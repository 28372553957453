
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: [],
  loading:false,
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCart:(state, action) => {
        state.value = action.payload;;
    },
    setCartLoading:(state, action) =>{
        state.loading = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setCart, setCartLoading } = cartSlice.actions

export default cartSlice.reducer
