import styles from "./product_list.module.css";
import productListBanner from "../../assets/image/product-list-banner.png";
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import productData from "../../database/products.json";
import Cards from "../../components/Cards/Cards";
import bestQuality from "../../assets/image/BestQualityTea.png";
import loseYourself from "../../assets/image/LoseYourself.png";
import Testimonial from "../home/components/testinomial";
import BrandBanner from "../home/components/brand_banner";
import { useSelector } from "react-redux";

export default function ProductList() {

    const cartReducer = useSelector((state) => state.cartReducer.value);
    const products = useSelector((state) => state.productReducer.value);

    return (
        <>
            <div className={styles.product_list_banner}>
                <img src={productListBanner} />
            </div>
            <div className={styles.products}>

                <div className={styles.product_list_title}>
                    <p>Tea Store</p>
                    {/* <TuneRoundedIcon style={{ color: "3D3C3C", cursor: "pointer" }} /> */}
                </div>
                <div className={styles.products_list}>
                    {
                        products?.docs?.map((item, index) => {
                            return (
                                <>
                                    <Cards data={item} key={index} index={index} cartItems={cartReducer} />
                                </>
                            )
                        })
                    }
                </div>

            </div>
            <div className={styles.best_quality_banner}>
                <div className={styles.best_quality}>
                    <img src={bestQuality} />
                </div>
                <div className={styles.lose_yourself}>
                    <img src={loseYourself} />
                </div>
            </div>
            <Testimonial />
            <BrandBanner />
        </>
    );
}