import style from "../components.module.css";
import noResult from "../../assets/image/no-results.png";
import { useNavigate } from "react-router-dom";


export default function NotFound() {
    const navigate = useNavigate();
    return (
        <div className={style.notFound}>
            <img src={noResult} alt="No results found" />
            <p>Nothing found!</p>
            <button className={style.back_to_home}
                onClick={(e) => {
                    navigate("/")

                }}
            >Home</button>
        </div>
    )
}