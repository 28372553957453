import { CircularProgress, Radio, TextField } from "@mui/material";
import styles from "../checkout.module.css";
import handler from "../../../utils/handler";

export default function UpdateAddressComponent({ setEditAddress, formik ,inProgress}) {
  return (
    <div className={styles.add_address_component}>
      <div className={styles.add_address_component_radio}>
        <Radio
          value="a"
          name="radio-buttons"
          checked={true}
          inputProps={{ 'aria-label': 'A' }}
          className={styles.radio_button}
        />
      </div>
      <div className={styles.add_address_component_detail}>
        <p className={styles.add_address_component_detail_heading}>Add A New Address</p>
        <div className={styles.add_content}>
          <div className={styles.add_input_fields}>
            <div className={styles.add_input_container}>
              <div className={styles.add_address_com_box}>
                <TextField
                  id="name"
                  name="name"
                  placeholder="Enter Your Name"
                  className={styles.input_field}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </div>
              <div className={styles.add_address_com_box}>
                <TextField
                  id="mobile"
                  name="mobileNumber"
                  placeholder="Enter Your Mobile"
                  className={styles.input_field}
                  value={formik.values.mobileNumber}
                  onChange={formik.handleChange}
                  error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
                  helperText={formik.touched.mobileNumber && formik.errors.mobileNumber}
                />
              </div>
            </div>
            <div className={styles.add_input_container}>
              <div className={styles.add_address_com_box}>
                <TextField
                  id="landmark"
                  name="landmark"
                  placeholder="Enter LandMark"
                  className={styles.input_field}
                  value={formik.values.landmark}
                  onChange={formik.handleChange}
                  error={formik.touched.landmark && Boolean(formik.errors.landmark)}
                  helperText={formik.touched.landmark && formik.errors.landmark}
                />
              </div>
              <div className={styles.add_address_com_box}>
                <TextField
                  id="pincode"
                  name="pinCode"
                  placeholder="Enter PinCode"
                  className={styles.input_field}
                  value={formik.values.pinCode}
                  onChange={async (e) => {
                    formik.handleChange(e);
                        try{
                            if(e.target.value.length === 6){
                                const response = await handler.apiHitPinCode(e.target.value);
                                formik.setFieldValue("city", response.postcode_details.city);
                                formik.setFieldValue("state", response.postcode_details.state);
                            }
                        }
                        catch(error){
                            console.log(error);
                        }
                  }}
                  error={formik.touched.pinCode && Boolean(formik.errors.pinCode)}
                  helperText={formik.touched.pinCode && formik.errors.pinCode}
                />
              </div>
            </div>
            <div className={styles.add_input_container}>
              <TextField
                id="address"
                name="address"
                placeholder="Enter Complete Address"
                className={styles.input_field}
                value={formik.values.address}
                onChange={formik.handleChange}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
            </div>
            <div className={styles.add_input_container}>
              <div className={styles.add_address_com_box}>
                <TextField
                  id="city"
                  name="city"
                  placeholder="Enter City"
                  className={styles.input_field}
                  value={formik.values.city}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                />
              </div>
              <div className={styles.add_address_com_box}>
                <TextField
                  id="state"
                  name="state"
                  placeholder="Enter State"
                  className={styles.input_field}
                  value={formik.values.state}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={formik.touched.state && formik.errors.state}
                />
              </div>
            </div>
            <div className={styles.add_submit}>
              <button
              disabled={inProgress}
                className={styles.submit_button}
                onClick={formik.handleSubmit}
              >
                {inProgress ?  <CircularProgress size={25} color="inherit" />
:"Submit"}
                
              </button>
              <div
                className={styles.add_address_cancel}
                onClick={() => {
                    setEditAddress(false);
                    formik.resetForm();
                }}
              >
                <p>Cancel</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
