import { Box, Typography } from "@mui/material";
import styles from "../components.module.css";
import teaGreenTeaImage from "../../assets/image/greenTealoginImage.png"
import Modal from '@mui/material/Modal';
import React from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: '90%',   // for extra-small screens (mobile)
        sm: '80%',   // for small screens (tablet)
        md: 600,     // for medium and larger screens (desktop)
    },
    bgcolor: 'background.paper',
    boxShadow: 24,
};


export default function LoginModel({ open, handleClose, loginFormik, showOtp, loading }) {


    return <>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} >
                <div className={styles.login_modal}>
                    <div className={styles.login_left}>
                        <img src={teaGreenTeaImage} />
                    </div>
                    <div className={styles.loign_right}>
                        <div className={styles.modal_close_button}>
                            <CancelIcon style={{ cursor: "pointer", color: "#ED1C24", fontSize: "30px" }}
                                onClick={() => {
                                    handleClose()
                                }}
                            />
                        </div>
                        <div className={styles.login_container}>
                            <AccountCircleRoundedIcon style={{ color: "#ED1C24", fontSize: "40px" }} className="login-user-icon"/>
                            <p className={styles.login_title}>Login</p>
                        </div>
                        <input
                            type="text"
                            name="mobileNumber"
                            placeholder="Enter Mobile Number"
                            className={loginFormik.touched.mobileNumber && loginFormik.errors.mobileNumber ? styles.login_input_error : styles.login_input}
                            value={loginFormik.values.mobileNumber}
                            onChange={loginFormik.handleChange}
                            onBlur={loginFormik.handleBlur}
                            disabled={showOtp}
                        />
                        {loginFormik.touched.mobileNumber && loginFormik.errors.mobileNumber ? (
                            <div className="error_message">{loginFormik.errors.mobileNumber}</div>
                        ) : null}

                        {showOtp && (
                            <>
                                <input
                                    type="text"
                                    name="otp"
                                    placeholder="Enter OTP"
                                    className={loginFormik.touched.otp && loginFormik.errors.otp ? styles.login_input_error : styles.login_input}
                                    value={loginFormik.values.otp}
                                    onChange={loginFormik.handleChange}
                                    onBlur={loginFormik.handleBlur}
                                />
                                {loginFormik.touched.otp && loginFormik.errors.otp ? (
                                    <div className="error_message">{loginFormik.errors.otp}</div>
                                ) : null}
                            </>
                        )}
                        <button onClick={loginFormik.handleSubmit} className={styles.login_button}>
                            <p> {showOtp ? "Verify OTP" : "Send OTP"}</p>
                        </button>
                    </div>
                </div>
            </Box>
        </Modal>
    </>
} 