
import { store } from '../store';
import { setCart } from '../slice/cartSlice';
import { apiFunc } from '../../service/service';
import { toast } from 'react-toastify';

export const getCartDetails = async (dispatch) => {
    //get data in store
    const isLoggedIn =  store.getState().authReducer.isLoggedIn;
    //calling function with condition
    isLoggedIn
        ? await getDbCartDetails(dispatch)
        : await getLocalCartDetails(dispatch);
};

// Add To Cart Db
const getDbCartDetails = async (dispatch) => {
    try {
        //calling get cart api
        const res = await apiFunc("get-cart-items", "service", [], {})
        dispatch(setCart(res.data));
    } catch (err) {
        console.log(err, "My Absolute Error");
     }
};

//get data in local storage
const getLocalCartDetails = async (dispatch, time) => {
    try {
        //get item in local storage
        const cart = JSON.parse(localStorage.getItem("products")) || [];
        const productsArray = await Promise.all(
            cart.map(async (item) => {
                //calling get product details api
                let product = {};
                let productDetails = await apiFunc("fetch-product-id", "service", [
                    item.product,
                ]);
                product.quantity = item.quantity;
                product["product"] = {...productDetails.data};
                console.log(productDetails?.data, "productDetails")
                return product;
            })
        );
        //set data in store
        setTimeout(
            () => {
                dispatch(setCart(productsArray));
            },
            200
        );
    } catch (err) {
        dispatch({ type: types.GET_CART_FAILURE, payload: err });
        console.log("Product Details", err);
    }
};


// add item in cart
export const addToCart = (dispatch, product) => {
    //get data in store
    const isLoggedIn = store.getState().authReducer.isLoggedIn;
    isLoggedIn ? addToDbCart(dispatch, product) : addToLocalCart(dispatch, product);
};


// add item to db
const addToDbCart = async (dispatch, product) => {
    try {
        await apiFunc(`add-to-cart`, `service`, [], { product, quantity: 1 })
        //calling functin
        getCartDetails(dispatch);
        toast.success("Item added to card");
    } catch (err) {

    }
};

export const clearCart = (dispatch) => {
    //get data in store
    const isLoggedIn = store.getState().authReducer.isLoggedIn;
    isLoggedIn ? clearDbCart(dispatch) : clearLocalCart(dispatch);
  };
  
  //increase item quantity in cart
  export const increaseQuantity = (dispatch, cartId, productId) => {
    //get data in store
    const isLoggedIn = store.getState().authReducer.isLoggedIn;
    //calling  function with condition
    isLoggedIn
      ? increaseDbQuantity(dispatch, cartId)
      : increaseLocalQuantity(dispatch, productId);
  };

export const decreaseQuantity = (dispatch, cartId, productId) => {
    //get data in store
    const isLoggedIn = store.getState().authReducer.isLoggedIn;
    //calling function with condition
    isLoggedIn
      ? decreaseDbQuantity(dispatch, cartId)
      : decreaseLocalQuantity(dispatch, productId);
  };

// add item to cart
const addToLocalCart = async (dispatch, product) => {
    try {
        // get data in local storage
        const cart = JSON.parse(localStorage.getItem("products")) || [];
        //get data in local storage
        localStorage.setItem("products", JSON.stringify([{ product, quantity: 1 }, ...cart]));
        let productDetails = await apiFunc("fetch-product-id", "service", [
            product,
        ]);
        const newItem = {
            product: productDetails?.data,
            quantity: 1,
        }
        const cartState = store.getState().cartReducer.value;
        console.log([newItem, ...cartState],"cartState");
        dispatch(setCart([newItem, ...cartState]));
        toast.success("Item added to card");
    } catch (err) {
    }
};


//clear item in db
const clearDbCart = async (dispatch) => {
    try {
        dispatch(setCart([]));
    } catch (err) { }
};

//claer item in local storage
const clearLocalCart = async (dispatch) => {
    try {
        //set data in local storage
        localStorage.setItem("products", JSON.stringify([]));
        dispatch(setCart([]));
    } catch (err) { }
};

//item increase quantity in db
const increaseDbQuantity = async (dispatch, id) => {
    try {
        //calling increase quantity api
        await apiFunc(`increase-quantity`, `service`, [id]);
        //calling function
        getCartDetails(dispatch);
    } catch (err) { }
};

//item increase quantity in local storage
const increaseLocalQuantity = async (dispatch, productId) => {
    try {
        const localCart = JSON.parse(localStorage.getItem("products"));
        localCart.forEach(
            (item) =>
                item.product === productId &&
                item.quantity++
        );
        localStorage.setItem("products", JSON.stringify(localCart));
        getCartDetails(dispatch);
    } catch (err) {
        console.log("Error error", err);
    }
};
//decrease quantity in db
const decreaseDbQuantity = async (dispatch, id) => {
    try {
        //calling decrease quantity api
        await apiFunc(`decrease-quantity`, `service`, [id]);
        //calling function
        getCartDetails(dispatch);
    } catch (err) { }
};
//decrease quantity in localstorage
const decreaseLocalQuantity = async (dispatch, productId, variantId) => {
    try {
        // Get data from local storage
        const localCart = JSON.parse(localStorage.getItem("products")) || [];
        
        // Update quantity and filter out items with zero quantity
        const updatedCart = localCart
            .map(item => {
                if (item.product === productId && item.variant === variantId) {
                    item.quantity--;
                }
                return item;
            })
            .filter(item => item.quantity > 0); // Remove items with zero quantity
        
        // Save updated cart to local storage
        localStorage.setItem("products", JSON.stringify(updatedCart));
        
        // Update cart details
        getCartDetails(dispatch);
    } catch (err) {
        console.error('Error updating cart:', err);
    }
};

export const addLocalCartToDb = async () => {
    try {
      //get data in local storage
      const cart = (await JSON.parse(localStorage.getItem("products"))) || [];
      const data = { items: cart };
      //calling api
      await apiFunc("bulk-products", "service", [], data);
      //set data in local storage
      localStorage.setItem("products", JSON.stringify([]));
    } catch (err) {}
  };