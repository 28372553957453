
import styles from "../product.module.css";


export default function NameBanners(params){
    return(
        <div className={styles.name_banners}>
            <h3>{params.product.title}</h3>
        </div>
    )
}