
import { Skeleton } from "@mui/material";
import styles from "../components.module.css";

export default function CardLoader() {
    return (
        <div className={styles.cards}>
             <Skeleton variant="rectangular" height={350} />
        </div>
    );
}
