import { useState } from 'react';
import { Collapse } from '@mui/material';
import styles from "../checkout.module.css";
import { addLocalCartToDb, getCartDetails } from '../../../redux/actions/cartActions';
import { loader, setProfile } from '../../../redux/slice/authSlice';
import { useDispatch, useSelector } from 'react-redux';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import handler from '../../../utils/handler';

import sheildImage from "../../../assets/image/shield.png";
import { apiFunc } from '../../../service/service';

export default function CheckoutLogin({setLoading}) {

    const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn)

    const authValue = useSelector((state) => state.authReducer.value)

    const [showOtp, setShowOtp] = useState(false)

    const dispatch = useDispatch();


    const loginFormik = useFormik({
        initialValues: {
            mobileNumber: '',
            email: '',
            otp: '', // Include otp in the initial values
        },
        validationSchema: Yup.object({
            mobileNumber: Yup.string()
                .matches(handler.mobileNumberRegex, "Please enter a valid mobile number")
                .required("Please enter mobile number"),
            email: Yup.string(),
            otp: showOtp ? Yup.string()
                .matches(handler.otpRegex, "Please enter a valid 6-digit OTP")
                .required("Please enter OTP") : Yup.string()
        }),
        onSubmit: values => {
            if (values.otp) {
                verifyOtpHandler(values);
            } else {
                loginHandler(values);
            }
        },
    });


    const loginHandler = async (values) => {
        dispatch(loader(true));
        try {
            const response = await apiFunc("login", "service", [], values);
            if (response.statusCode === 200) {
                setShowOtp(true);
            }
            dispatch(loader(false));
        }
        catch (error) {
            dispatch(loader(false));
        }
    }


    const verifyOtpHandler = async (values) => {
        try {
            setLoading(true);
            const response = await apiFunc("verify-otp", "service", [], values);
            if (response.statusCode === 200) {
                dispatch(setProfile(response.data));
                await addLocalCartToDb()
                await  getCartDetails(dispatch)
                setOpen(false);
                setLoading(false);
            }
            dispatch(loader(false));
        }
        catch (error) {
            dispatch(loader(false));
            setLoading(false);
        }
    }

    return (
        <div className={styles.checkout_login}>
            <div className={styles.accordion}>
                <div className={styles.accordion_header} >
                    <div className={styles.login_heading}>
                        <div className={styles.login_number}>
                            <p>1</p>
                        </div>
                        {
                            isLoggedIn ? <div className={styles.user_loggedIn}>

                                <p className={styles.user_name}>{authValue.name || "User"} </p>
                                <p className={styles.user_contact}>{authValue.mobileNumber}</p>
                            </div> :
                                <h1>Login Or Signup</h1>
                        }
                    </div>
                </div>
                <Collapse in={!isLoggedIn} timeout="auto" unmountOnExit>
                    <div className={styles.accordion_body}>
                        <div className={styles.accordion_left_body}>
                            <div className={styles.loign_right}>
                                <input
                                    type="text"
                                    name="mobileNumber"
                                    placeholder="Enter Mobile Number"
                                    className={loginFormik.touched.mobileNumber && loginFormik.errors.mobileNumber ? styles.login_input_error : styles.login_input}
                                    value={loginFormik.values.mobileNumber}
                                    onChange={loginFormik.handleChange}
                                    onBlur={loginFormik.handleBlur}
                                    disabled={showOtp}
                                />
                                {loginFormik.touched.mobileNumber && loginFormik.errors.mobileNumber ? (
                                    <div className="error_message">{loginFormik.errors.mobileNumber}</div>
                                ) : null}

                                {showOtp && (
                                    <>
                                        <input
                                            type="text"
                                            name="otp"
                                            placeholder="Enter OTP"
                                            className={loginFormik.touched.otp && loginFormik.errors.otp ? styles.login_input_error : styles.login_input}
                                            value={loginFormik.values.otp}
                                            onChange={loginFormik.handleChange}
                                            onBlur={loginFormik.handleBlur}
                                        />
                                        {loginFormik.touched.otp && loginFormik.errors.otp ? (
                                            <div className="error_message">{loginFormik.errors.otp}</div>
                                        ) : null}
                                    </>
                                )}
                                <button onClick={loginFormik.handleSubmit} className={styles.login_button}>
                                    <p>{ showOtp ? "Verify OTP" :  "Send OTP" }</p>
                                </button>
                            </div>
                        </div>
                        <div className={styles.accordion_right_body}>
                            <img src={sheildImage} />
                            <p>Safe and Secure Payments. Easy returns. 100% Authentic products.</p>
                        </div>
                    </div>
                </Collapse>
            </div>
        </div>
    );
}
