
import styles from "../home.module.css";

import cup from "../../../assets/image/cup.png";
import leaf from "../../../assets/image/leaf.png";
import pot from "../../../assets/image/pot.png";
import batch from "../../../assets/image/batch.png"
import playButton from "../../../assets/image/play_button.png"

export default function KickStartCelebration() {
    return (
        <div className={styles.kick_start_celebration} id="about">
            <div className={styles.kick_start_left}>
                <p className={styles.kick_start_sub_heading}>Kick Start Your Celebration</p>
                <p className={styles.kick_start_heading}>The Story Behind Our Green Tea</p>
                <p className={styles.kick_start_detail}>At Kanwari Food & Trading Company Our Endeavour Is To Provide Best Tea Products To The Consumers. We Serve The Consumers Under The Brand Name ”Kanwari”. We Have Dedicated Team Of Professionals Who Work Day-In & Day-Out To Bring The Best Possible Products For Our Customers At Affordable Prices.</p>
                <div className={styles.kick_start}>
                    <div>
                        <img src={leaf} />
                        <p className={styles.kick_start_title}>100% Natural Tea</p>
                        <p className={styles.kick_start_body}>Savor the natural goodness in every drop.</p>
                    </div>
                    <div>
                        <img src={batch} />
                        <p className={styles.kick_start_title}>Finest Quality</p>
                        <p className={styles.kick_start_body}>Elevate Your Tea Experience</p>
                    </div>
                </div>
                <div className={styles.kick_start}>
                    <div>
                        <img src={pot} />
                        <p className={styles.kick_start_title}>Your Daily Dose of Freshness!</p>
                        <p className={styles.kick_start_body}>Your Daily Freshness Fix</p>
                    </div>
                    <div>
                        <img src={cup} />
                        <p className={styles.kick_start_title}>Lose Yourself
                            In Our World of Tea</p>
                        <p className={styles.kick_start_body}>Your Daily Freshness Fix</p>
                    </div>
                </div>
            </div>
            <div className={styles.kick_start_right}>
                <div className={styles.kick_start_image}>
                    <img src={playButton} onClick={() => {
                        window.open('https://www.youtube.com/watch?v=6Qglvd2tXUE', '_blank');
                    }} />
                </div>
            </div>
        </div>
    );
}
