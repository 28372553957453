import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading:false,
  isLoggedIn:false
}

export const counterSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loader:(state, action) => {
        state.loading = action.payload;
    },
    setProfile:(state, action) => {
        state.value = action.payload;
        state.isLoggedIn = true;
        localStorage.setItem("authToken", action.payload.token)

    },
    setOnlyProfile:(state, action) => {
      state.value = action.payload;
      state.isLoggedIn = true;
    },
    logout:(state) => {
        localStorage.removeItem("authToken");
        state.isLoggedIn = false;
        state.value = {}
    },
  },
})

// Action creators are generated for each case reducer function
export const { loader, setProfile, logout,setOnlyProfile } = counterSlice.actions

export default counterSlice.reducer