
import { useEffect, useState } from 'react';
import { Collapse, Radio } from '@mui/material';
import styles from "../checkout.module.css";
import { useSelector } from 'react-redux';
import { apiFunc } from '../../../service/service';
import AddAddressComponent from './AddAddressComponent';
import { useFormik } from "formik";
import * as Yup from "yup";
import handler from '../../../utils/handler';
import UpdateAddressComponent from './UpdateAddressComponent';


const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    mobileNumber: Yup.string()
        .matches(handler.mobileNumberRegex, "Enter a valid mobile number")
        .required("Mobile number is required"),
    landmark: Yup.string().required("Landmark is required"),
    pinCode: Yup.string()
        .matches(/^\d{6}$/, "Enter a valid 6-digit Pincode")
        .required("Pincode is required"),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
});

export default function Address({setBillingAddress}) {

    const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn)




    const [selectedAddress, setSelectedAddress] = useState(null)

    const [openAddress, setOpenAddress] = useState(false);

    const [addAddress, setAddAddress] = useState(false);
    const [edtiAddress, setEditAddress] = useState(false)
    const [inProgress, setInProgress] = useState(false);
    const [addressId, setAddressId] = useState("");
    const [address, setAddress] = useState([]);

    const getAllAddress = async () => {
        try {
            const response = await apiFunc("get-address", "service", [], {});
            if (response.statusCode == 200) {
                setAddress(response.data);
            }
        }
        catch (error) {

        }
    }

    useEffect(() => {
        isLoggedIn && getAllAddress();
    }, [isLoggedIn]);




    const formik = useFormik({
        initialValues: {
            name: "",
            mobileNumber: "",
            landmark: "",
            pinCode: "",
            address: "",
            city: "",
            state: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            addAddressHandler(values);
        },
    });


    const updateFormik = useFormik({
        initialValues: {
            name: "",
            mobileNumber: "",
            landmark: "",
            pinCode: "",
            address: "",
            city: "",
            state: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            updateAddressHandler(values);
        },
    });

    const updateAddressHandler = async (values) => {
        try{
            setInProgress(true);
            const response = await apiFunc("update-address","service", [addressId], values);
            if(response.statusCode == 200){ 
                setEditAddress(false);
                // setSelectedAddress(response.data.data);
                updateFormik.resetForm();
                setOpenAddress(false);
                setInProgress(false);
                getAllAddress();
            }
        }
        catch(error){
            setInProgress(false);
            console.log("response", error);
        }
    }

    const addAddressHandler = async (values) => {
        try{
            setInProgress(true);
            const response = await apiFunc("add-address","service", [], values);
            if(response.statusCode == 200){ 
                setAddAddress(false);
                // setSelectedAddress(response.data.data);
                formik.resetForm();
                // setOpenAddress(false);
                setInProgress(false);
                getAllAddress();
            }
        }
        catch(error){
            console.log("My Error code errr", error);
            setInProgress(false);
        }
    }

    return (
        <div className={styles.address}>
            <div className={styles.accordion}>
                <div className={styles.accordion_header}
                    onClick={() => {
                        setOpenAddress(false);
                    }}
                >
                    <div className={styles.login_heading}>
                        <div className={styles.login_number}>
                            <p>2</p>
                        </div>
                        {


                            selectedAddress ? <div className={styles.user_loggedIn}>

                                <p className={styles.user_name}>{selectedAddress?.name} {selectedAddress.mobileNumber}</p>
                                <p className={styles.user_contact}>{selectedAddress.address}-{selectedAddress.pinCode}</p>
                            </div> :
                                <div className={styles.user_loggedIn}>
                                    <p className={styles.user_name}>Select Address</p>
                                </div>
                        }
                    </div>
                </div>
                <Collapse in={!openAddress} timeout="auto" unmountOnExit>
                    <div className={styles.accordion_body_address}>
                        {
                            !addAddress && !edtiAddress ? <>
                                {
                                    address?.map((item, index) => {
                                        return (
                                            <>
                                                <div className={styles.address_body}>
                                                    <div className={styles.address_left}>
                                                        <Radio
                                                            value="a"
                                                            name="radio-buttons"
                                                            checked={selectedAddress?._id === item?._id}
                                                            inputProps={{ 'aria-label': 'A' }}
                                                            className={styles.radio_button}
                                                            onClick={() => {
                                                                setSelectedAddress(item);
                                                                setOpenAddress(true);
                                                                console.log(item?._id);
                                                                setBillingAddress(item?._id);
                                                            }}
                                                        />
                                                        <div className={styles.address_detail}>
                                                            <p className={styles.user_detail}>{item?.name} <span>{item.mobileNumber}</span></p>
                                                            <p className={styles.add_detail}>{item?.address} <span>{item.pinCode}</span></p>
                                                            <button className={styles.choose_address}
                                                                onClick={() => {
                                                                    setSelectedAddress(item);
                                                                    setOpenAddress(true);
                                                                    console.log(item?._id);
                                                                    setBillingAddress(item?._id);
                                                                }}
                                                            >
                                                                Deliver Here
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className={styles.address_right}>
                                                        <p onClick={()=> {
                                                            setAddressId(item?._id);
                                                            updateFormik.setValues({...item});
                                                            setEditAddress(true);
                                                        }}>Edit</p>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                                <div className={styles.add_address}>
                                    <p
                                        onClick={() => {
                                            setAddAddress(true);
                                        }}
                                    >Add New Address</p>
                                </div>
                            </> : <></>
                        }
                        {
                            addAddress ? <><AddAddressComponent setAddAddress={setAddAddress} formik={formik} inProgress={inProgress}/></> : <></>
                        }
                        {
                            edtiAddress ? <><UpdateAddressComponent setEditAddress={setEditAddress} formik={updateFormik} inProgress={inProgress}/></>:<></>
                        }
                    </div>
                </Collapse>
            </div>
        </div>
    )
}
