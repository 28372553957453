import './App.css'
import StripLabel from './components/strips/strip'
import { BrowserRouter, Navigate, useRoutes } from 'react-router-dom'
import Home from './app/home/home';
import Footer from './components/footer/footer';
import Navigation from './components/navigations/navigation';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./button_global.css";
import Products from './app/products/products';
import ProductList from './app/products_list/ProductList';
import Cart from './app/cart/Cart';
import History from './app/history/History';
import OrderDetailPage from './app/order_detail_page/OrderDetailPage';
import Profile from './app/profile/Profile';
import EditProfile from './app/profile/components/EditProfile';
import CheckoutPage from './app/checkout/checkout';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CancellationReturnPolicy from './app/policies/CancellationReturn';
import PrivacyPolicy from './app/policies/PrivacyPolicy';
import TermsOfUse from './app/policies/TermCondition';
import ShippingPolicies from './app/policies/ShippingPolicies';
import NotFound from './components/NotFound/NotFound';



function App() {

  const AppRoutes = () => {
    return useRoutes([
      {
        path: '/',
        element: <Home />,
        children: [],
      },
      {
        path: '/kanwari',
        element: <Home />,
        children: [],
      },
      {
        path:"/products-list",
        element: <ProductList />,
        children:[]
      },
      {
        path:"/cart",
        element: <Cart />,
        children:[

        ]
      },
      {
        path:"/history",
        element: <History />,
        children:[]
      },
      {
        path:"/order-detail/:id",
        element: <OrderDetailPage />,
        children:[]
      },
      {
        path: '/product/:id',
        element: <Products />, 
        children:[]
      },
      {
        path:"/checkout",
        element:<CheckoutPage/>,
        children:[]
      },
      {
        path:"/cancel-return-policy",
        element:<CancellationReturnPolicy />,
        children:[]
      },
      {
        path:"/privacy-policy",
        element:<PrivacyPolicy />,
        children:[]
      },
      {
        path:"/terms-conditions",
        element:<TermsOfUse />,
        children:[]
      },
      {
        path:"/shipping-policy",
        element:<ShippingPolicies />,
        children:[]
      },
      {
        path:"/not-found",
        element:<NotFound />,
        children:[]
      },
      {
        path: '/user',
        element: <Profile />,
        children:[
          {
            path:"profile",
            element:<EditProfile />,
            children:[]
          }
        ] 
      },
    ]);
  };

  return (
    <>
      <div className='main_page'>
        <BrowserRouter>
          <StripLabel />
          <Navigation />
          <AppRoutes />
          <Footer />
          <ToastContainer />
        </BrowserRouter>
      </div>
    </>
  )
}

export default App
