
import { CircularProgress, TextField } from "@mui/material";
import styles from "../profile.module.css";
import { useSelector } from "react-redux";
import { setIn, useFormik } from "formik";
import * as Yup from "yup";
import handler from "../../../utils/handler";
import { apiFunc } from "../../../service/service";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";


const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    mobileNumber: Yup.string()
        .matches(handler.mobileNumberRegex, "Enter a valid mobile number")
        .required("Mobile number is required"),
    email:Yup.string().email("Enter valid email").required("Email is required")
});

export default function EditProfile() {

    const [inProgress, setInProgress] = useState(false);
    const userValues = useSelector((state) => state.authReducer.value);
    const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);


    const formik = useFormik({
        initialValues: {
            name: userValues?.name,
            mobileNumber: userValues?.mobileNumber,
            email:userValues?.email
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            editProfile(values);
        },
        
    });


    useEffect(()=> {
        formik.setValues({
            name: userValues?.name,
            mobileNumber: userValues?.mobileNumber,
            email:userValues?.email
        });
    }, [isLoggedIn]);

    const editProfile = async () => {
        try{
            setInProgress(true);
            await apiFunc("edit-profile", "service", [], {});
            setInProgress(false);
            toast.success("Profile Updated Successfully");
        }
        catch(error){
            setInProgress(false);
        }
    }

    return (
        <>
            <div className={styles.edit_profile_component}>
                <TextField
                    id="outlined-size-small"
                    placeholder="First Name"
                    size="small"
                    name="name"
                    className={styles.edit_profile_field}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                />
                <TextField
                    id="outlined-size-small"
                    placeholder="Mobile Number"
                    size="small"
                    name="mobileNumber"
                    className={styles.edit_profile_field}
                    value={formik.values.mobileNumber}
                    onChange={formik.handleChange}
                    error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
                    helperText={formik.touched.mobileNumber && formik.errors.mobileNumber}
                />

                <TextField
                    id="outlined-size-small"
                    placeholder="Email"
                    size="small"
                    name="email"
                    className={styles.edit_profile_field}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                />

            </div>
            <div className={styles.save_button}>
                <button onClick={formik.handleSubmit}>{!inProgress ? "Save" : <CircularProgress size={15} />}</button>
            </div>
        </>
    );
}