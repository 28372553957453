import axios from 'axios';
import apis from '.';
import config from '../config/config';

const services = {
  service: apis,
};

export const apiFunc = async (name, category, args = [], bodyData = {}) => {
  // Retrieve the service object for the given category and name
  const currentServiceObj = services[category]?.find(service => service.name === name);

  // Get the authorization token from localStorage
  const userObj = localStorage.getItem('authToken');

  if (currentServiceObj) {
    // Construct the request URL
    const currentPath = `${config.BASE_URL}${currentServiceObj.path}${args.map(item => encodeURIComponent(item)).join('/')}`;

    try {
      // Make the API request using axios
      const response = await axios({
        method: currentServiceObj.method,
        url: currentPath,
        params: currentServiceObj.method === 'GET' ? bodyData : {}, // Include params for GET requests
        data: currentServiceObj.method !== 'GET' ? bodyData : {}, // Include data for non-GET requests
        headers: {
          'Content-Type': currentServiceObj.isUploadFile ? 'multipart/form-data' : 'application/json',
          'Authorization': userObj ? `Bearer ${userObj}` : undefined // Set authorization header if token is present
        }
      });

      // Return the response data
      return response.data;
    } catch (err) {
      // Handle errors and provide a detailed message
      const errorMessage = err.response?.data?.message || err.message || 'An unknown error occurred';
      console.error('API Error:', errorMessage);
      throw new Error(errorMessage);
    }
  } else {
    throw new Error(`Service ${name} not found in category ${category}`);
  }
};
