import styles from "./order_detail.module.css";
import OrderDetailPageImage from "../../assets/image/OrderDetailPageImage.png";
import productImage from "../../assets/image/cartImage.png";
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { apiFunc } from "../../service/service";
import moment from "moment";
import config from "../../config/config";
import InvoiceModal from "./components/InvoiceModal";

export default function OrderDetailPage() {
    const { id } = useParams();
    const defaultStep = [
        { label: 'Order Confirmed', date: 'Fri, 19 July' },
        { label: 'Shipped', date: 'Sat, 20 July' },
        { label: 'Out For Delivery', date: 'Sun, 22 July' },
        { label: 'Delivered', date: 'Mon, 23 July' },
    ];


    const [steps, setSteps] = useState(defaultStep);
    const [comparePrice, setComparePrice] = useState(0);
    const [orderHistory, setOrderHistory] = useState({});
    const [discount, setDiscount] = useState({
        discountAmount: 0,
        discountPercentage: 0
    });

    const fetchMyOrders = async () => {
        try {
            const response = await apiFunc("get-orders", "service", [id], {});
            if (response.statusCode == 200) {
                setOrderHistory(response.data);
                console.log(response.data, "response.data");
                let stepLister = [];

                response?.data?.orderStatusProgress?.map((item, index) => {
                    if (item.status !== "Pending") {
                        stepLister.push({ label: item.status, date: moment(item.date).format("DD/MM/YYYY") })
                    }
                });

                let compare_price = 0;

                response.data.orders.map((item, index) => {
                    compare_price += item.item.comparePrice * item.quantity;
                })

                if (response.data.coupon) {
                    setDiscount({
                        discountAmount: Math.floor(response.data.totalAmount * (response.data.coupon.discount / 100)),
                        discountPercentage: response.data.coupon.discount
                    })
                }

                setComparePrice(compare_price);

                setSteps(stepLister);
            }
        }
        catch (error) {

        }
    }




    useEffect(() => {
        fetchMyOrders();
    }, []);

    return (
        <div className={styles.order_detail_page}>
            <div className={styles.order_detail_banner}>
                <img src={OrderDetailPageImage} />
            </div>
            <div className={styles.order_detail}>
                <div className={styles.order_detail_heading}>
                    <div className={styles.order_detail_info}>
                        <p className={styles.order_id}>Order ID {id}</p>
                        <p className={styles.order_place_date}>Order Place on {moment(orderHistory?.createdAt).format("DD/MM/YYYY hh:mm a")}</p>
                    </div>
                    {/* <p className={styles.delivery_status_update}>Your item has been delivered</p> */}
                </div>
                <div className={styles.order_detail_body}>
                    <div className={styles.order_item}>
                        {
                            orderHistory?.orders?.map((item, index) => {
                                return (
                                    <>
                                        <div className={styles.order_items_list} style={{ marginTop: index == 0 ? "0px" : "20px" }}>
                                            <div className={styles.item_image}>
                                                <img src={`${config.URL}${item?.item?.thumbnailImg}`} />
                                            </div>
                                            <div className={styles.item_detail}>
                                                <div className={styles.product_name_weight}>
                                                    <p className={styles.product_name}>{item?.item?.productName}</p>
                                                </div>
                                                <div className={styles.product_quantiy_price}>
                                                    <p className={styles.item_quanity}>Qty: {item.quantity}</p>
                                                    <p className={styles.item_price}>₹ {item.amount}</p>
                                                    <p className={styles.compare_price}>₹ {item?.item?.comparePrice}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }

                    </div>
                    <div className={styles.order_delivery_status}>
                        <div className={styles.orderStatus}>
                            <ul className={styles.stepper}>
                                {steps.map((step, index) => (
                                    <li key={index} className={styles.step}>
                                        <div className={styles.stepIndicator}>
                                            <div className={styles.circle}></div>
                                            {index !== steps.length - 1 && <div className={styles.line}></div>}
                                        </div>
                                        <div className={styles.stepDetails}>
                                            <p className={styles.label}>{step.label}</p>
                                            <p className={styles.date}>{step.date}</p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <hr className={styles.horizontol_name} />
                        <div className={styles.download_invoice}>
                            <p>Download Invoice</p>
                            <DownloadForOfflineRoundedIcon
                                style={{ fontSize: "30px", cursor: "pointer", color: "#ED1C24" }}
                            />
                        </div>
                        <hr className={styles.horizontol_name} />
                        <div className={styles.price_details}>
                            <p className={styles.price_heading}>Price Details</p>
                            <div className={styles.price_body}>
                                <p>Price {orderHistory?.orders?.length} Item</p>
                                <p>₹ {comparePrice}</p>
                            </div>
                            {
                                orderHistory?.coupon ?
                                    <div className={styles.price_body}>
                                        <p>Coupon Discount</p>
                                        <p>-{discount.discountPercentage}%</p>
                                    </div>
                                    : <></>
                            }
                            <div className={styles.price_body}>
                                <p>Total Discount</p>
                                <p>-₹{(comparePrice) - orderHistory.subTotal}</p>
                            </div>
                            <div className={styles.price_body}>
                                <p>Delivery Charges</p>
                                <p> ₹ {orderHistory?.shippingCharges>0 ? `${orderHistory?.shippingCharges}`: 'Free'}</p>
                            </div>
                            <div className={styles.price_body_final}>
                                <p>Total Amount</p>
                                <p>₹ {orderHistory?.totalAmount}</p>
                            </div>
                        </div>
                        <div className={styles.delivery_address}>
                            <p className={styles.delivery_address_heading}>Delivery Details</p>
                            <div className={styles.delivery_address_body}>
                                <p>{`${orderHistory?.billingAddress?.address} ${orderHistory?.billingAddress?.city} ${orderHistory?.billingAddress?.state} - ${orderHistory?.billingAddress?.pinCode}`}</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

