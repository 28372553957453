
import logo from "../../assets/images/logo_kanwari.png"
import styles from "../components.module.css";
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import SearchProduct from "../search_products/search_products";
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge'; // Import Badge

// Mobile Navigations Icons
import HomeIcon from '@mui/icons-material/Home';
import teaStoreIcon from "../../assets/image/TeaStore.png"
import LoginModel from "../login/LoginModel";
import { apiFunc } from "../../service/service";

// Redux

import { useSelector, useDispatch } from 'react-redux'
import { loader, logout, setOnlyProfile, setProfile } from "../../redux/slice/authSlice";
import { setLoading, setProducts } from "../../redux/slice/productSlice";

// Formik Validation

import { useFormik } from 'formik';
import * as Yup from 'yup';
import handler from "../../utils/handler";


// Mui Menu

import {
    Menu,
    MenuItem,
    IconButton,
    Avatar,
    ListItemIcon,
    Divider,
    Typography,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { addLocalCartToDb, getCartDetails } from "../../redux/actions/cartActions";



export default function Navigation() {

    const navigate = useNavigate();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openSearchDrawer, setSeachOpenDrawer] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [showOtp, setShowOtp] = useState(false);


    // Redux Handle

    const loading = useSelector((state) => state.authReducer.loading)
    const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn)
    const cart = useSelector((state) => state.cartReducer.value);
    const dispatch = useDispatch();


    // Menu Handler

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };



    const loginFormik = useFormik({
        initialValues: {
            mobileNumber: '',
            email: '',
            otp: '', // Include otp in the initial values
        },
        validationSchema: Yup.object({
            mobileNumber: Yup.string()
                .matches(handler.mobileNumberRegex, "Please enter a valid mobile number")
                .required("Please enter mobile number"),
            email: Yup.string(),
            otp: showOtp ? Yup.string()
                .matches(handler.otpRegex, "Please enter a valid 6-digit OTP")
                .required("Please enter OTP") : Yup.string()
        }),
        onSubmit: values => {
            if (values.otp) {
                verifyOtpHandler(values);
            } else {
                loginHandler(values);
            }
        },
    });


    const loginHandler = async (values) => {
        dispatch(loader(true));
        try {
            const response = await apiFunc("login", "service", [], values);
            if (response.statusCode === 200) {
                setShowOtp(true);
            }
            dispatch(loader(false));
        }
        catch (error) {
            dispatch(loader(false));
        }
    }


    const verifyOtpHandler = async (values) => {
        try {
            const response = await apiFunc("verify-otp", "service", [], values);
            if (response.statusCode === 200) {
                await addLocalCartToDb()
                dispatch(setProfile(response.data));
                setOpen(false);

            }
            dispatch(loader(false));
        }
        catch (error) {
            dispatch(loader(false));
        }
    }


    const checkIsUserLogin = async () => {
        try {
            dispatch(loader(true));
            const response = await apiFunc("check-token", "service", [], {});
            if (response.statusCode === 200) {
                dispatch(setOnlyProfile(response.data));
                dispatch(loader(false));
            }
        }
        catch (error) {
            dispatch(loader(false));
        }
    }

    useEffect(() => {
        const authToken = localStorage.getItem("authToken");
        if (authToken) checkIsUserLogin();
    }, []);


    // fetched Products

    const fetchProducts = async () => {
        try {
            dispatch(setLoading(true));
            const response = await apiFunc("fetch-products", "service", [], {});
            if (response.statusCode == 200) {
                dispatch(setProducts(response.data));
                dispatch(setLoading(false));
            }
        }
        catch (error) {

        }
    }



    useEffect(() => {
        fetchProducts();
    }, []);


    useEffect(() => {
        getCartDetails(dispatch)
    }, [isLoggedIn]);

    return (
        <>
            <SearchProduct open={openSearchDrawer} setClose={() => { setSeachOpenDrawer(false) }} />
            <div className={styles.navigation}>
                <div className={styles.nav_image_container}>
                    <img
                        src={logo}
                        className={styles.logoImg}
                        alt="Picture of the author"
                        onClick={() => {
                            navigate("/");
                        }}
                    />
                </div>
                <div className={styles.navigation_links}>
                    <li onClick={() => {
                        navigate("");
                    }}>Home</li>
                    <li
                        onClick={() => {
                            navigate("/products-list");
                        }}
                    >Our Products</li>
                    <li
                        onClick={() => {
                            navigate("/#about");
                        }}
                    >About Us</li>
                    <li
                        onClick={() => {
                            navigate("#contact-us");
                        }}
                    >Contact Us</li>
                </div>

                <div className={styles.navigation_items}>
                    {/* <SearchIcon className={styles.navigation_item_icon} /> */}
                    <PersonIcon className={`${styles.navigation_item_icon} ${styles.user_icon_navigation}`}
                        onClick={(e) => {
                            if (isLoggedIn) {
                                handleClickMenu(e);
                            }
                            else {
                                handleOpen()
                            }
                        }}
                    />
                    <Menu
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleCloseMenu}
                        transformOrigin={{ horizontal: "right", vertical: "top" }}
                        // anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                        disableScrollLock={true} // Add this line to prevent the background shift

                    >
                        <MenuItem onClick={() => {
                            navigate("/user/profile")
                            handleCloseMenu();
                        }}>
                            <ListItemIcon>
                                <Avatar sx={{ width: 24, height: 24 }} />
                            </ListItemIcon>
                            <Typography variant="inherit">My Profile</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => {
                            navigate("/history")
                            handleCloseMenu();
                        }}>
                            <ListItemIcon>
                                <ShoppingCartIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit">Orders</Typography>
                        </MenuItem>
                        {/* <MenuItem>
                            <ListItemIcon>
                                <SettingsIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit">Settings</Typography>
                        </MenuItem> */}
                        <Divider />
                        <MenuItem onClick={() => {
                            dispatch(logout())
                            handleCloseMenu();
                            loginFormik.resetForm();
                            navigate("/")
                        }}>
                            <ListItemIcon

                            >
                                <ExitToAppIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit">Logout</Typography>
                        </MenuItem>
                    </Menu>
                    <div style={{ position: "relative", width:"50px", height:"50px", display:"flex",alignItems:"center",justifyContent:"center" }}>
                        <ShoppingCartIcon className={styles.navigation_item_icon}

                            onClick={() => {
                                navigate("/cart")
                            }}
                        />
                        <div className={styles.badge_content} style={{ zIndex: "9999" }}>
                            <p>{cart?.length || 0}</p>
                        </div>
                    </div>
                </div>

            </div>
            <div className={styles.mobile_buttom_navigation}>
                <div onClick={() => {
                    navigate("")
                }}>
                    <HomeIcon className={styles.mobile_selected_icon} />
                    <p className={styles.selected_text}>Home</p>
                </div>
                <div onClick={() => {
                    navigate("/products-list");
                }}>
                    <img src={teaStoreIcon} className={`${styles.mobile_selected_icon} ${styles.tea_store_icon}`} />
                    <p className={styles.unselected_text}>Tea Store</p>
                </div>
                <div onClick={() => {
                    navigate("/cart");
                }}>
                    <ShoppingCartIcon className={styles.mobile_icon} />
                    <p className={styles.unselected_text}>Cart</p>
                </div>
                <div onClick={(e) => {
                    if (isLoggedIn) {
                        handleClickMenu(e);
                    }
                    else {
                        handleOpen()
                    }
                }}>
                    <PersonIcon className={styles.mobile_icon} />
                    <p className={styles.unselected_text}>Account</p>
                </div>
            </div>
            <LoginModel
                open={open}
                handleClose={handleClose}
                loginFormik={loginFormik}
                showOtp={showOtp}
                loading={loading}
            />
        </>
    );
}


