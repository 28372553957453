import { Helmet } from "react-helmet";

function SEO({ title, description }) {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta name="description" content={description} />
        </Helmet>
    )
}


export default SEO;