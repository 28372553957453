"use client"

import styles from "../components.module.css";
import logo from "../../assets/images/logo_kanwari.png";
import { useNavigate } from "react-router-dom";


export default function Footer() {
    let navigate = useNavigate();
    const navigateToZero = async () => {
        window.scrollTo(0, 0);
    }
    return (
        <>
            <div className={styles.footer_strip} id="footer"></div>
            <div className={styles.footer}>
                <div className={styles.footer_content}>
                    <div className={styles.brand_logo}>
                        <img
                            src={logo}
                            className={styles.brand_image}
                            alt="Brand Logo"
                        />
                    </div>
                    <div className={styles.other_content}>
                        <div>
                            <h1>Quick Links</h1>
                            <br />
                            <p
                                onClick={() => {
                                    navigateToZero()
                                    navigate("/products-list")
                                }}
                            >Green Tea</p>
                        </div>
                        <div>
                            <h1>Details</h1>
                            <br />
                            <p
                                onClick={() => {
                                    
                                    navigate("#about")
                                }}
                            >About us</p>
                            <p
                                onClick={() => {
                                    navigateToZero()
                                    navigate("/shipping-policy")
                                }}
                            >Shipping Policy</p>
                            <p
                                onClick={() => {
                                    navigateToZero()
                                    navigate("/cancel-return-policy")
                                }}
                            >Cancellation & Return</p>
                            <p
                                onClick={() => {
                                    navigateToZero()
                                    navigate("/terms-conditions")
                                }}
                            >Term & Conditions</p>
                            <p
                                onClick={() => {
                                    navigateToZero()
                                    navigate("/privacy-policy")
                                }}
                            >Privacy Policy</p>
                        </div>
                        <div>
                            <h1>Social Media</h1>
                            <br />
                            <p
                                onClick={() => {
                                    window.open("https://www.facebook.com/profile.php?id=100092947539168&sk=friends", "_blank")
                                }}
                            >Facebook</p>
                            <p
                                onClick={() => {
                                    window.open("https://x.com/kanwarifood", "_blank")
                                }}
                            >Twitter</p>
                            <p
                                onClick={() => {
                                    window.open("https://www.instagram.com/kanwarifood/profilecard/?igsh=Z20zNGJpNmdubzky", "_blank")
                                }}
                            >Instagram</p>
                            <p
                                onClick={() => {
                                    window.open("https://quora.com/profile/Kanwari-Food", "_blank")
                                }}
                            >Quora</p>
                            <p
                                onClick={() => {
                                    window.open("https://in.pinterest.com/ecommercekanwarifood/", "_blank")
                                }}
                            >Pinterest</p>
                        </div>
                        <div id="contact-us">
                            <h1>Site Information</h1>
                            <br />
                            <p>Greater Noida G. B. Nagar</p>
                            <p>+91-8750812035</p>
                            <p>info@kanwari.com</p>
                        </div>
                    </div>
                </div>
                <div className={styles.footer_copyright_content}>
                    <p>Copyright 2024, All Rights Reserved.</p>
                </div>
            </div>
        </>
    );
}


