import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading:false,
}

export const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProducts:(state, action) => {
        state.value = action.payload;;
    },
    setLoading:(state, action) =>{
        state.loading = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setProducts, setLoading } = productSlice.actions

export default productSlice.reducer