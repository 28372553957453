"use client";

import styles from "../home.module.css";
import userProfile from "../../../assets/image/customer_default_icon.png";
import starts from "../../../assets/image/start.png";

export default function Testimonial() {


    return (
        <div className={styles.testimonial_container}>
            <div className={styles.testimonial}>
                <div className={styles.testimonial_content}>
                    <img src={userProfile}/>
                    <img src={starts} className={styles.starts}/>
                </div>
                <div className={styles.testimonial_comment}>
                    <p className={styles.testimonial_user}>Anjali Sharma</p>
                    <p className={styles.testimonial_user_comment}>
                    For premium tea blends from India, Kanwari is unbeatable. They source their teas directly from Indian farmers, eliminating the middlemen to sell exclusively online. I absolutely love their products!
                    </p>
                </div>
            </div>
            <div className={`${styles.testimonial} ${styles.testimonial_second}`}>
                <div className={styles.testimonial_content}>
                    <img src={userProfile}/>
                    <img src={starts} className={styles.starts}/>
                </div>
                <div className={styles.testimonial_comment}>
                    <p className={styles.testimonial_user}>Rahul Bhalla</p>
                    <p className={styles.testimonial_user_comment}>Kanwari has transformed my daily tea ritual! The fresh, rich flavors and the care they put into sourcing directly from farmers make all the difference. You can taste the authenticity in every sip. I couldn’t be more satisfied</p>
                </div>
            </div>
        </div>
    );
}
