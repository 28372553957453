
import styles from "./profile.module.css";
import { Link, Outlet, useNavigate } from 'react-router-dom';

import profilePicture from "../../assets/image/profile_picture.png"
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';

export default function Profile({children}){

    const navigate = useNavigate();

    return (
        <div className={styles.user_profile}>
            <div className={styles.user_banner}>
                <h1>Profile</h1>
            </div>
            <div className={styles.profile_body}>
                <div className={styles.left_container}>
                    <div className={styles.profile_picture}>
                        <img src={profilePicture}/>
                    </div>
                    <div className={styles.sidebar_links}>
                        <div>
                            <AccountCircleRoundedIcon className={styles.url_icon} style={{color:"#ED1C24"}}/>
                            <p>Profile</p>
                        </div>
                        {/* <div>
                            <LocationOnRoundedIcon className={styles.url_icon}/>
                            <p>Address</p>
                        </div> */}
                        <div onClick={() => {
                            navigate("/history");
                        }}>
                            <LocalShippingRoundedIcon className={styles.url_icon}/>
                            <p>My Orders</p>
                        </div>
                        <div>
                            <LogoutRoundedIcon className={styles.url_icon}/>
                            <p>Logout</p>
                        </div>
                    </div>
                </div>
                <div className={styles.right_container}>
                        <Outlet />
                </div>
            </div>
        </div>
    );
}


