import { useDispatch } from "react-redux";
import CustomButton from "../buttons/custom_button";
import styles from "../components.module.css";
import { addToCart } from "../../redux/actions/cartActions";
import { useNavigate } from "react-router-dom";
import config from "../../config/config";

export default function Cards({ data, cartItems = [] }) {
    // Ensure cartItems is always an array, even if it's undefined

    const navigate = useNavigate();

    const isProductInCart = cartItems?.length > 0 && cartItems.some(cartItem => cartItem?.product?._id === data?._id);
    
    const dispatch = useDispatch();

    return (
        <div className={styles.cards}
        onClick={(e) => {
            navigate(`/product/${data.slug}`)
            e.stopPropagation();
        }}
        >
            <div className={styles.product_thumbnail}>
                <div className={styles.image_container}>
                    <img 
                        src={`${config.URL}${data.thumbnailImg}`}
                        className={styles.product_image}
                        alt="Thumbnail Img"
                    />
                </div>
                <h1 className={styles.product_title}>{data.title}</h1>
                <p className={styles.price_range}>₹ {data.price}</p>
                <CustomButton 
                    text={isProductInCart ? "Go To Cart" : "Add To Cart"} 
                    callBack={(e) => {
                        e.stopPropagation();
                        if (isProductInCart) {
                            navigate(`/cart`)
                            // Navigate to cart page
                        } else {
                            addToCart(dispatch, data._id);
                            // Add product to cart
                        }
                    }} 
                    dynamicStyle="buy_now_button" 
                />
            </div>
        </div>
    );
}
