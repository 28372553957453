import styles from './tpolicies.module.css';

export default function ShippingPolicies() {
    return (
        <div className={`${styles.termsOfUse} ${styles.shipping_policy}`}>
            <h1 className={styles.title}>Shipping Policy</h1>
            
            <h2 className={styles.sectionTitle}>Pre-paid Orders</h2>
            <p className={styles.paragraph}>
                Free shipping applies to all prepaid orders above INR 300 (except J&K and North-East territories).
                A shipping charge of INR 50 will be applied to all prepaid orders below INR 300.
            </p>

            <h2 className={styles.sectionTitle}>Delivery ETA</h2>
            <p className={styles.paragraph}>
                The service has a delivery ETA of 5-7 days from the date of dispatch.
                Products are generally dispatched within 48 hours of receiving the order.
            </p>
        </div>
    );
}
