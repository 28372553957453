import { useNavigate, useParams } from 'react-router-dom';

import { useEffect, useState } from 'react';
import ProductsPage from './components/product_page';
import Loading from '../../components/Loading/loading';
import { apiFunc } from '../../service/service';
import { useSelector } from 'react-redux';



export default function Products() {
    const { id } = useParams();
    const [product, setProducts] = useState({});
    const [isLoading, setIsLoading] = useState(true);


    const navigate = useNavigate();

    const cartReducer = useSelector((state) => state.cartReducer.value);
    let [isProductInCart, setIsProductInCart] = useState(false);

    // Scroll to Top

    const fetchProductApi = async (slug) => {
        try{
            setIsLoading(true);
            const response = await apiFunc("fetch-product-slug", "service", [slug], {});
            if(response.statusCode == 200){
                setProducts(response.data);
                setTimeout(() => {
                    setIsLoading(false);
                }, 500)

            }
        }
        catch(error){
            if (error.message == "No product found with this id") {
               navigate("/not-found");
            }
            setIsLoading(false);
        }
    }




    useEffect(() => {
        const handleRouteChange = () => {
            window.scrollTo(0, 0);
        };

        handleRouteChange();

    }, [id]);

    useEffect(() => {
        fetchProductApi(id)
    }, [id]);

    useEffect(() => {
        if(cartReducer?.length>0 && product){
            setIsProductInCart(cartReducer?.length > 0 && cartReducer.some(cartItem => cartItem.product._id === product._id));
        }
    }, [cartReducer, product]);

    return (
        <>
            {
                isLoading ? <Loading /> : <ProductsPage id={id} product={product} isProductInCart={isProductInCart}/>
            }
        </>
    )
}