import React from 'react';
import styles from './tpolicies.module.css'; // Updated import for new CSS module

const TermsOfUse = () => {
  return (
    <div className={styles.termsOfUse}>
      <h1 className={styles.title}>Terms of Use</h1>

      <h2 className={styles.sectionTitle}>1) Introduction</h2>
      <p className={styles.paragraph}>
        Kanwari Tea (<a href="https://www.kanwari.com" target="_blank" rel="noopener noreferrer">www.kanwari.com</a>) is a website (the “Website”) operated by  KANWARI FOOD & TRADING PRIVATE LIMITED (Indian Private Limited Company) . The Website Owner, including subsidiaries and affiliates (or "we" or "us" or "our") provides the information contained on this Website or any of the pages comprising the Website to visitors ("Visitors") (cumulatively referred to as "you" or "your" hereinafter) subject to the terms and conditions set out in these Website terms and conditions, the privacy policy and any other relevant terms and conditions, policies, and notices which may be applicable to a specific section or module of this website. Please read this agreement carefully. By browsing, accessing or using this website or by using any facilities or services made available through it or by transacting through or on it, you are agreeing to the terms and conditions that appear below (all of which are called the "Agreement"). This Agreement is made between you and us.
      </p>

      <h2 className={styles.sectionTitle}>2) Website Availability</h2>
      <ul className={styles.list}>
        <li>Kanwari Tea may suspend the Website for any reason whatsoever, including but not limited to repairs, planned maintenance, or upgrades, and shall not be liable to you for any such suspension.</li>
        <li>Kanwari Tea reserves the right to make any changes to the Website or to discontinue any aspect or feature of the Website without notice.</li>
        <li>In the event that Kanwari Tea, in its sole discretion, considers that you are making any illegal and/or unauthorized use of the Website, and/or your use of the Website is in breach of these Terms, Kanwari Tea reserves the right to take any action that it deems necessary, including terminating without notice your use of the Website and, in the case of illegal use, instigating legal proceedings.</li>
      </ul>

      <h2 className={styles.sectionTitle}>3) Your Status</h2>
      <ul className={styles.list}>
        <li>You are legally capable of entering into binding contracts; and</li>
        <li>You are at least 18 years old</li>
      </ul>

      <h2 className={styles.sectionTitle}>4) The Contract Between You and The Partner</h2>
      <ul className={styles.list}>
        <li>
          After placing an Order, you will receive an email from Kanwari Tea acknowledging that Kanwari Tea has received your order. Please note that this does not mean that your Order has been accepted. Your Order constitutes an offer to Kanwari Tea to buy a Product from the third party that offers the Product for sale via the Website (the Partner). All orders are subject to acceptance by the Partner, and the Partner will confirm such acceptance to you by sending you an email that confirms that the Product has been dispatched (the Dispatch Confirmation). The contract between the Partner and you (Contract) will only be formed when the relevant Partner sends you the Dispatch Confirmation.
        </li>
        <li>
          Each Contract relates only to those Products whose dispatch the Partner has confirmed in the Dispatch Confirmation. The Partner shall not be obliged to supply any other Products which may have been part of your Order until the dispatch of such Products has been confirmed by way of a Dispatch Confirmation.
        </li>
        <li>For the avoidance of doubt, your contract with Kanwari Tea relates only to your use of the Website and the Services available therein.</li>
      </ul>

      <h2 className={styles.sectionTitle}>5) Credit Card Payment</h2>
      <p className={styles.paragraph}>
        In a credit card transaction, you must use your own credit card. We will not be liable for any credit card fraud. The liability to use a card fraudulently will be on the user and the onus to 'prove otherwise' shall be exclusively on the user.
      </p>

      <h2 className={styles.sectionTitle}>6) Trademarks</h2>
      <p className={styles.paragraph}>
        The trademarks, names, logos, and service marks (collectively "trademarks") displayed on this website are registered and unregistered trademarks of the Website Owner and the suppliers of the products listed on the Website. Nothing contained on this website should be construed as granting any license or right to use any trademark without the prior written permission of the Website Owner.
      </p>

      <h2 className={styles.sectionTitle}>7) External Links</h2>
      <p className={styles.paragraph}>
        External links may be provided for your convenience, but they are beyond the control of the Website Owner and no representation is made as to their content. Use or reliance on any external links and the content thereon provided is at your own risk. When visiting external links, you must refer to that external website's terms and conditions of use. No hypertext links shall be created from any website controlled by you or otherwise to this website without the express prior written permission of the Website Owner. Please contact us at <a href="mailto:sales@vahdamteas.com">sales@vahdamteas.com</a> if you would like to link to this website or would like to request a link to your website.
      </p>

      <h2 className={styles.sectionTitle}>8) Specific Use</h2>
      <p className={styles.paragraph}>
        You further agree not to use the website to send or post any message or material that is unlawful, harassing, defamatory, abusive, indecent, threatening, harmful, vulgar, obscene, sexually oriented, racially offensive, profane, pornographic, or violates any applicable law and you hereby INDEMNIFY the Website Owner against any loss, liability, damage, or expense of whatever nature which the Website Owner or any third party may suffer which is caused by or attributable to, whether directly or indirectly, your use of the website to send or post any such message or material.
      </p>

      <h2 className={styles.sectionTitle}>9) Kanwari Tea Refund Policy</h2>
      <p className={styles.paragraph}>
        Products returned by you because of a defect will be refunded in full, including a standard delivery cost incurred by you in returning the item to us. Kanwari Tea will not refund opened or used product. All refunds will be initiated through a Bank NEFT/RTGS transfer.
      </p>

      <h2 className={styles.sectionTitle}>10) Disclaimer of Liability</h2>
      <p className={styles.paragraph}>
        The Website Owner shall not be responsible for and DISCLAIMS all liability for any loss, liability, damage (whether direct, indirect, or consequential), personal injury, or expense of any nature whatsoever which may be suffered by you or any third party (including your company), as a result of or which may be attributable, directly or indirectly, to your access and use of the website, any information contained on the website, your or your company's personal information or material and information transmitted over our system. In PARTICULAR, neither the Website Owner nor any third party or data or content provider shall be liable in any way to you or to any other person, firm, or corporation whatsoever for any loss, liability, damage (whether direct or consequential), personal injury, or expense of any nature whatsoever arising from any delays, inaccuracies, errors in, or omission of any share price information or the transmission thereof, or for any actions taken in reliance thereon or occasioned thereby or by reason of non-performance or interruption, or termination thereof.
      </p>

      <h2 className={styles.sectionTitle}>11) User of the Website</h2>
      <p className={styles.paragraph}>
        The Website Owner does not make any warranty or representation that information on the website is appropriate for use in any jurisdiction (other than India). By accessing the website, you warrant and represent to the Website Owner that you are legally entitled to do so and to make use of the information made available via the website.
      </p>

      {/* Additional terms go here in a similar format */}
    </div>
  );
};

export default TermsOfUse;
