import axios from "axios";

const mobileNumberRegex = /^[6-9]\d{9}$/;
const otpRegex = /^\d{6}$/;


const apiHitPinCode = async (pinCode) => {
    if (pinCode.length === 6) {
      const bodyData = {
        postcode: pinCode,
      };
      try {
        //calling get address api using pincode
        const res = await axios({
          params: bodyData,
          method: "GET",
          url: `https://apiv2.shiprocket.in/v1/external/open/postcode/details`,
        });
        return res?.data;
        // -----------------
      } catch (err) {
        if (err.response?.data?.status_code === 403) {
          //value set in state
          // ---------
        }
      }
    }
  };


export default {
    mobileNumberRegex,
    otpRegex,
    apiHitPinCode
}