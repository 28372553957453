
import BannerSliders from "./components/banner_slider";
import Testimonial from "./components/testinomial";
import BrandBanner from "./components/brand_banner";
import SEO from "../../components/seo/seo";
import PopularProductList from "./components/PopularProductList";
import KickStartCelebration from "./components/KickStartCelebration";
import BestSellingProductList from "./components/BestSellingProduct";
import styles from "./home.module.css";
import bestQuality from "../../assets/image/BestQuality2.png";
import loseYourself from "../../assets/image/LoseYourself.png";
import ComboProductList from "./components/ComboProductList";
import { useEffect } from "react";




export default function Home() {


    useEffect(() => {
        window.scrollTo(0,0)
    }, []);


    return (
        <>
            <SEO
                title="Kanwari"
                description="Discover the finest selection of premium green teas from around the world. Shop organic, matcha, sencha, and more. Elevate your tea experience with our curated blends and expert brewing tips"
            />
            <BannerSliders />
            <PopularProductList   />
            <KickStartCelebration />
            <ComboProductList />
            <BestSellingProductList />
            <div className={styles.best_quality_banner}>
                <div className={styles.best_quality}>
                    <img src={bestQuality} />
                </div>
                <div className={styles.lose_yourself}>
                    <img src={loseYourself} />
                </div>
            </div>
            <Testimonial />
            <BrandBanner />
     
        </>
    );
}