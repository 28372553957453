import { Skeleton } from "@mui/material";
import styles from "../history.module.css";
export default function LoadingHistory() {
    return (
        <>
        <div className={styles.history_products}>
            <div className={styles.history_product_details}>
                <div className={styles.product_detail}>
                    <Skeleton variant="rectangular" width={60} height={60} />

                    <div className={styles.product_name_quantity}>
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="rectangular" width={250} height={120} />
                    </div>
                </div>
                <div className={styles.rating_review}>

                    <div className={styles.starts}>
                        <Skeleton variant="rectangular" width={100} height={20} />
                    </div>
                </div>
                <div className={styles.delivery_status}>
                    <Skeleton variant="rectangular" width={100} height={10} />
                </div>
            </div>
        </div>
        <hr className={styles.horizontol_name} />
        </>
    )
}