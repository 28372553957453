"use client"

import styles from "../product.module.css";
import starFilled from "../../../assets/images/starFilled.png";
import { useState } from "react";
import CustomButton from "../../../components/buttons/custom_button";
import Modal from "../../../components/modal/modal";
import { useNavigate } from "react-router-dom";
import { addToCart } from "../../../redux/actions/cartActions";
import { useDispatch } from "react-redux";

export default function ProductContent(params) {
    let rating = [starFilled, starFilled, starFilled, starFilled, starFilled];


    const dispatch = useDispatch();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };



    return (<div className={styles.product_content}>
        <div className={styles.title_start}>
            <h3 className={styles.product_title}>{params.product.title}</h3>
            <div className={styles.rating}>
                {
                    rating?.map((item, index) => {
                        return (
                            <img
                                src={item}
                                alt="rating"
                                className={styles.rating_star}
                            />
                        );
                    })
                }
            </div>
        </div>
        <p className={styles.brief_description}>{params.product.specification}</p>
        <p className={styles.price}>₹ {params.product.price}</p>
        <button className={styles.cart_button}
        onClick={(e) => {
            if(params.isProductInCart){
                navigate("/cart")
            }else{
                addToCart(dispatch, params.product._id);
            }
        }}
        >{ params.isProductInCart ? "Go To Cart" : "Add To Cart"}</button>
        <hr style={{marginBottom:"30px", marginTop:"30px"}}/>
        <p className={styles.description_heading}>Description</p>
        <p className={styles.description_body}>
            {params.product.description}
        </p>

    </div>)
}