const BASE_URL="https://kanwari.com/api/v1";
const URL = "https://kanwari.com/api/v1";

// const BASE_URL="http://localhost:8080/api/v1";
// const URL = "http://localhost:8080";


// const RAZORPAY_KEY = "rzp_test_YPHFneMX1EYZvk";
const RAZORPAY_KEY = "rzp_live_UZGAitFvDFZDxc"

export default  {
    BASE_URL,
    URL,
    RAZORPAY_KEY
}