import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function CustomButton({ text, callBack, dynamicStyle }) {

  const { hash } = useLocation();
  const [lastHash, setLastHash] = useState('');
  const navigate = useNavigate();

  // Handle scroll when hash changes
  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });

        // Clear the hash after scrolling
        setTimeout(() => {
          navigate('', { replace: true });
        }, 1000); // Adjust the delay if necessary
      }
    }
  }, [hash, navigate]);

  // Handle clicking the same hash link
  useEffect(() => {
    if (hash === lastHash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
    setLastHash(hash);
  }, [hash, lastHash]);


  return (
    <>
      <button className={`${dynamicStyle}`}
        onClick={callBack}
      >{text}</button>
    </>
  )
}