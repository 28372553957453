import styles from "./cart.module.css";
import cartBanner from "../../assets/image/YouCartImage.png";
import productImage from "../../assets/image/cartImage.png";
import { useDispatch, useSelector } from "react-redux";
import { decreaseQuantity, increaseQuantity } from "../../redux/actions/cartActions";
import emptyCart from "../../assets/image/empty-cart.png"
import { useNavigate } from "react-router-dom";
import config from "../../config/config";
import { useEffect } from "react";

export default function Cart() {

    const cartReducer = useSelector((state) => state.cartReducer.value);
    console.log(cartReducer, "cartReducer");
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const totalPrice = cartReducer.length && cartReducer?.reduce((acc, item) => acc + (item?.product?.price * item?.quantity), 0);
    const totalDiscount = cartReducer.length && cartReducer?.reduce((acc, item) => acc + ((item?.product?.comparePrice - item?.product?.price) * item.quantity), 0);
    const totalAmount = cartReducer.length && cartReducer?.reduce((acc, item) => acc + ((item?.product?.comparePrice) * item?.quantity), 0);



    useEffect(() => {
        window.scrollTo(0 , 0);
    }, []);

    return (
        <div className={styles.cart_container}>
            <div className={styles.cart_banner}>
                <img src={cartBanner} />
            </div>
            {
                cartReducer?.length == 0  ? <>
                    <div className={styles.empty_cart}>
                        <img src={emptyCart} />
                        <h1>Cart Is Empty</h1>
                    </div>
                </> : <>
                    <div className={styles.cart_detail}>
                        <div className={styles.cart_heading}>
                            <h1 >Your Cart Items</h1>
                        </div>
                        <hr className={styles.horizontol_name} />
                        <div className={styles.cart_products_title}>
                            <div className={styles.cart_item_image}>
                                <p>Items</p>
                            </div>
                            <div className={styles.cart_item_info}>
                                <p>Price</p>
                            </div>
                            <div className={styles.cart_item_info}><p>Quantity</p></div>
                            <div className={styles.cart_item_info}><p>Total Amount</p></div>
                        </div>
                        {
                            cartReducer.length > 0 && cartReducer?.map((item, index) => {
                                return (
                                    <>
                                        <hr className={styles.horizontol_name} />
                                        <div className={styles.cart_products_body}>
                                            <div className={styles.cart_body_image}>
                                                <img src={`${config.URL}${item?.product?.thumbnailImg}`} />
                                                <div className={styles.product_detail}>
                                                    <p>{item?.product?.productName}</p>
                                                </div>
                                            </div>
                                            <div className={styles.cart_body_price}>
                                                <p>₹ {item?.product?.price}</p>
                                                <p className={styles.compare_price}>₹ {item?.product?.comparePrice}</p>
                                            </div>
                                            <div className={styles.cart_body_quantity}>
                                                <div className={styles.quantity_button}>
                                                    <div className={styles.decrement}
                                                        onClick={() => {
                                                            decreaseQuantity(dispatch, item?._id, item?.product?._id);
                                                        }}
                                                    ><p>-</p></div>
                                                    <div className={styles.quantity}><p>{item?.quantity}</p></div>
                                                    <div className={styles.increment}
                                                        onClick={() => {
                                                            increaseQuantity(dispatch, item?._id, item?.product?._id);
                                                        }}
                                                    ><p>+</p></div>
                                                </div>
                                            </div>
                                            <div className={styles.cart_body_tamount}>₹ {item?.product?.price * item?.quantity}</div>
                                        </div>
                                    </>
                                )
                            })
                        }
                        <hr className={styles.horizontol_name} />
                        {
                            cartReducer.length > 0 && cartReducer?.map((item, index) => {
                                return (
                                    <>

                                        <div className={styles.mobile_cart}>
                                            <div className={styles.mobile_cart_item}>
                                                <div className={styles.item_detail}>
                                                    <img src={`${config.URL}${item?.product?.thumbnailImg}`} />
                                                    <div className={styles.name_price_detail}>
                                                        <p>{item?.product?.productName}</p>
                                                        <p>₹ {item?.product?.price}</p>
                                                    </div>
                                                </div>
                                                <div className={styles.item_quantity}>
                                                    <div className={styles.quantity_button}>
                                                        <div className={styles.decrement}
                                                            onClick={() => {
                                                                decreaseQuantity(dispatch, item?._id, item?.product?._id);
                                                            }}
                                                        ><p>-</p></div>
                                                        <div className={styles.quantity}><p>{item?.quantity}</p></div>
                                                        <div className={styles.increment}
                                                            onClick={() => {
                                                                increaseQuantity(dispatch, item?._id, item?.product?._id);
                                                            }}
                                                        ><p>+</p></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                        <div className={styles.order_details}>
                            <div className={styles.order_detail_content}>
                                <div className={styles.price_details}>
                                    <div className={styles.detail_item}>
                                        <span className={styles.amount}>Price ({cartReducer?.length} {cartReducer?.length > 1 ? 'items' : 'item'})</span>
                                        <span className={styles.amount}>₹ {totalAmount}</span>
                                    </div>
                                    <div className={styles.detail_item}>
                                        <span className={styles.amount} >Saved</span>
                                        <span className={styles.amount} style={{ textDecorationLine: "line-through" }}>₹ {totalDiscount}</span>
                                    </div>
                                    <div className={styles.detail_item}>
                                        <span className={styles.amount}>Delivery Charges</span>
                                        <span className={styles.amount}>{totalPrice < 299 ? `₹ ${50}` : 'Free'}</span>
                                    </div>
                                    <div className={styles.detail_item}>
                                        <span className={styles.amount}>Total Amount</span>
                                        <span className={styles.total_amount}>₹ {totalPrice < 299 ? (totalPrice+50): totalPrice}</span>
                                    </div>
                                    <div className={styles.checkout_button}>
                                        <button
                                            onClick={() => {
                                                navigate("/checkout")
                                            }}
                                        >Checkout</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }


        </div>
    );
}
