
import styles from "../components.module.css";
import closeImg from "../../assets/images/close.png";
import searchImg from "../../assets/images/search-light-image.svg"
import productData from "../../database/products.json"
import { useState } from "react";
import teaImages from "../../utils/import_images_product_list";
import Fuse from "fuse.js";
import { useNavigate } from "react-router-dom";


const options = {
    includeScore: true,
    keys: ['title']
};


export default function SearchProduct({ open, setClose }) {

    const [textSearch, setTextSearch] = useState("");
    const [searchedProducts, setSearchProducts] = useState([]);


    const fuse = new Fuse(productData, options);



    const navigate = useNavigate();


    const searchProduct = (searchTerm) => {
        const results = fuse.search(searchTerm);

        // Extract the items from results (Fuse.js returns an array of objects with item and score)
        const products = results.map(result => result.item);
    
        setSearchProducts(products.slice(0, 6));
    };




    return (
        <div className={`${styles.search_product} ${open ? styles.open_search : ''}`}>
            <div className={styles.cross_navigation_icon_search}>
                <img
                    src={closeImg}
                    onClick={() => {
                        setTextSearch("");
                        setSearchProducts([])
                        setClose()
                    }}
                    className={styles.close_image_search}
                />
            </div>
            <div className={styles.search_input_container}>
                <p className={styles.search_title}>What are you looking for?</p>
                <div className={styles.input_container}>
                    <input
                        type="text"
                        className={styles.text_input}
                        autoFocus={open}
                        value={textSearch}
                        onChange={(e) => {
                            setTextSearch(e.target.value);
                            if (e.target.value.length > 2) {
                                searchProduct(e.target.value);
                            }
                            else {
                                setSearchProducts([])
                            }
                        }}
                        placeholder="Search for product..."
                    />
                    <img
                        src={searchImg}
                        className={styles.searchImg}
                        alt="Picture of the author"
                    />
                </div>
            </div>
            <div className={styles.searched_products}>
                {
                    searchedProducts?.map((item, index) => {
                        return (<>
                            <div className={styles.search_product_item}
                                key={index}
                                onClick={() => {
                                    setClose();
                                    navigate(`/product/${item.slug}`)
                                    setTextSearch("");
                                    setSearchProducts([])
                                }}
                            >
                                <div className={styles.search_product_image_container}>
                                    <img
                                        src={teaImages[item.slug][0]}
                                        className={styles.search_product_image}
                                    />
                                </div>
                                <div className={styles.search_product_detail}>
                                    <p className={styles.search_title}>{item.title}</p>
                                    <p className={styles.search_price}>₹ {item.price_range}</p>
                                </div>
                            </div>
                        </>)
                    })
                }
            </div>
        </div>
    )
}



